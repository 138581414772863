import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import lightbulb from "../../assets/images/lightbulb.svg";
import { getDashboardComponyDeatailsRequest } from "../../store/componies/action";
// import SearchInput from "../Dashboard/searchField";
import * as ComponiesActions from "../../store/componies/action";
import CommonInput from "./commonInput";
import IndustryDropDown from "./industryDropDown";
import "./index.scss";
import { GetOnboardSuggestions } from "../ResumeOpimizer/suggestions";

// const { Option } = Select;

const CompanyNotFoundCard = ({ setselectedComp, selectedComp }) => {
  const dispatch = useDispatch();
  const dashboardState = useSelector((state) => state.dashboard);
  const companyList = useSelector((state) => state.componies.autoComplet);
  const companyDetails = useSelector(
    (state) => state.componies.dashboardCompanyDetails
  );
  const companyLoader = useSelector((state) => state.componies.Loader);
  const [selected, setSelected] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [details, setDetails] = useState([
    { label: "Company Name", value: "", type: "text", name: "name" },
    {
      label: "LinkedIn URL",
      value: "",
      type: "text",
      name: "linkedinUrl",
      // options: dashboardState.companyFilteres?.size_list || [],
    },
    // {
    //   label: "Industry",
    //   value: "",
    //   type: "select",
    //   name: "industry",
    //   options: dashboardState.companyFilteres?.industry_list || [],
    // },
    // { label: "Company Website", value: "", type: "text", name: "url" },
    // {
    //   label: "Company Type",
    //   value: undefined,
    //   type: "select",
    //   name: "company_type",
    //   options: dashboardState.companyFilteres?.type_list || [],
    // },
    // { label: "Location", value: "", type: "text", name: "location" },
  ]);

  const handleChange = (e) => {
    let foundIndex = details.map((item) => item.name).indexOf(e.target.name);
    let arr = [...details];
    arr[foundIndex] = { ...arr[foundIndex], value: e.target.value };
    setDetails(arr);
    setselectedComp({ ...selectedComp, [e.target.name]: e.target.value });
  };

  const handleSelect = (e) => {
    dispatch(
      getDashboardComponyDeatailsRequest({
        // company_name: e.text,
        unnlock_id: e.value,
      })
    );
    setSelected(e.value);
  };

  const handleSearchChange = (e) => {
    setInputValue(e.target.value);
    dispatch(ComponiesActions.getDashboardComponyDeatailsFailure());
    if (e.target.value) {
      setSuggestions([]);
      dispatch(
        ComponiesActions.companiesAutoCompleteRequest({
          companyName: e.target.value,
        })
      );
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    if (
      Array.isArray(dashboardState.topCompanies) &&
      dashboardState.topCompanies.length
    ) {
      handleChange({
        target: { value: dashboardState.topCompanies[0].name, name: "name" },
      });
    }
  }, [dashboardState.topCompanies]);

  useEffect(() => {
    if (Array.isArray(companyList) && companyList.length) {
      setSuggestions(() =>
        companyList.map((item) => {
          return {
            value: item.unnlock_id,
            text: item.company,
            logo_url: item.logo_url,
          };
        })
      );
    }
  }, [companyList]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <div className="CompanyNotFoundCard">
      <div className="companySelection">
        <div className="left">
          <div
            className="title"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              Company not found? No problem. Enter a few details to get back on track
            </div>
            {companyLoader && (
              <div>
                <Spin indicator={antIcon} />
              </div>
            )}
          </div>

          <div className="notfoundCard" style={{}}>
            <div className="titleCard" style={{ marginBottom: "20px" }}>
              <div>
                <p>1.</p>
                <span>Select a similar or competing company</span>
              </div>
            </div>
            {/* <div className="similarCompany" style={{ marginBottom: "30px" }}>
              <SearchInput
                placeholder="Company Name"
                onChange={handleSelect}
                setselectedComp={() => {}}
                style={{
                  border: "1px solid #dee5ff",
                  padding: "7px 15px",
                  borderRadius: "8px",
                }}
                initialEmpty
              />
              <div className="infoContainer">
                <img src={lightbulb} />
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                  do
                </p>
              </div>
            </div> */}
            <div className="ConfirmYourCompanyCard" style={{ padding: "0px" }}>
              {/* <div className="devider" /> */}
              <div style={{ padding: "10px" }}>
                <CommonInput
                  label="Company Name"
                  name="companyName"
                  placeholder="Enter company name"
                  value={inputValue}
                  handleChange={handleSearchChange}
                />
              </div>
              <div className="suggestions">
                {companyList?.message ? (
                  <h4 style={{ paddingLeft: "10px" }}>
                    {companyList?.message}
                  </h4>
                ) : (
                  <>
                    {suggestions.map((item) => (
                      <div
                        className={
                          selected === item.value
                            ? "suggestion-selected"
                            : "suggestion"
                        }
                        onClick={() => {
                          if (companyLoader) {
                            unnlockNotification("Please wait!", "info");
                          } else {
                            handleSelect(item);
                          }
                        }}
                      >
                        {item.text}
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="titleCard">
              <div>
                <p>2.</p>
                <span>Enter your current or most recent company details (optional)</span>
              </div>
            </div>
            <div className="descriptions">
              {details.map((item, i) => (
                <>
                  {/* {item.name === "industry" ? (
                    <div className="industry_input_wrapper">
                      <IndustryDropDown
                        placeholder="Industry"
                        value=""
                        handleChange={(e) => {
                          let obj = {
                            target: {
                              name: item.name,
                              value: e,
                            },
                          };
                          handleChange(obj);
                        }}
                        styles={{
                          border: "1px solid #dee5ff",
                          marginBottom: "13px",
                          padding: "7px 15px",
                          borderRadius: "8px",
                        }}
                      />
                    </div>
                  ) : ( */}
                  <CommonInput
                    label={item.label}
                    placeholder={`Enter ${item.label}`}
                    value={item.value}
                    name={item.name}
                    handleChange={(e) => {
                      if (item.type === "select") {
                        let obj = {
                          target: {
                            name: item.name,
                            value: e,
                          },
                        };
                        handleChange(obj);
                      } else {
                        handleChange(e);
                      }
                    }}
                    type={item.type}
                    options={item?.options || []}
                    //disabled={item.name === "name" && item.name}
                    style={
                      item.type === "select"
                        ? {
                          border: "1px solid #dee5ff",
                          marginBottom: "13px",
                          padding: "7px 15px",
                          borderRadius: "8px",
                        }
                        : { marginBottom: "13px" }
                    }
                  />
                  {/* )} */}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="right">
          <GetOnboardSuggestions mainTitle="Why" screen="companyNotfound" />
        </div>
      </div>
    </div>
  );
};

export default CompanyNotFoundCard;
