/*eslint-disable*/
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { setCurrentResumePage } from "../../../store/resumeOptimizer/resumeOptimizerActions";
import EditButton from "./editButton";
// import ContentEditable from "react-contenteditable";
import {
  PDFViewer,
  PDFDownloadLink,
  Page,
  Document,
  Font,
  usePDF,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import { renderToStaticMarkup } from "react-dom/server";
import { Slider, Switch } from "antd";
import sanitizeHtml from "sanitize-html";
import ExpereinceDraggable from "./experienceDraggable";

const checkForEmpty = (arr) => {
  if (Array.isArray(arr) && arr.length) {
    if (arr.length === 1) {
      if (Object.values(arr[0]).every((val) => val === "")) {
        return false;
      } else {
        return true;
      }
    }
    return true;
  } else {
    return false;
  }
};
Font.register({
  family: "Poppins",
  fonts: [
    {
      fontWeight: "100",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrLPTed3FBGPaTSQ.ttf",
    },
    {
      fontWeight: "100",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiAyp8kv8JHgFVrJJLmE3tFOvODSVFF.ttf",
    },
    {
      fontWeight: "200",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLFj_V1tvFP-KUEg.ttf",
    },
    {
      fontWeight: "200",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmv1plEN2PQEhcqw.ttf",
    },
    {
      fontWeight: "300",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8V1tvFP-KUEg.ttf",
    },
    {
      fontWeight: "300",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm21llEN2PQEhcqw.ttf",
    },
    {
      fontWeight: "normal",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrFJDUc1NECPY.ttf",
    },
    {
      fontWeight: "normal",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiGyp8kv8JHgFVrJJLed3FBGPaTSQ.ttf",
    },
    {
      fontWeight: "500",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLGT9V1tvFP-KUEg.ttf",
    },
    {
      fontWeight: "500",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmg1hlEN2PQEhcqw.ttf",
    },
    {
      fontWeight: "600",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf",
    },
    {
      fontWeight: "600",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmr19lEN2PQEhcqw.ttf",
    },
    {
      fontWeight: "700",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLCz7V1tvFP-KUEg.ttf",
    },
    {
      fontWeight: "700",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLmy15lEN2PQEhcqw.ttf",
    },
    {
      fontWeight: "800",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDD4V1tvFP-KUEg.ttf",
    },
    {
      fontWeight: "800",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm111lEN2PQEhcqw.ttf",
    },
    {
      fontWeight: "900",
      fontStyle: "normal",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLBT5V1tvFP-KUEg.ttf",
    },
    {
      fontWeight: "900",
      fontStyle: "italic",
      src: "https://fonts.gstatic.com/s/poppins/v20/pxiDyp8kv8JHgFVrJJLm81xlEN2PQEhcqw.ttf",
    },
  ],
});
// Font.register({
//   family: 'Roboto',
//   fonts: [
//     {fontWeight: 100, src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1MmgWxPKTM1K9nz.ttf"},
//     {fontWeight: 100, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOiCnqEu92Fr1Mu51QrIzcXLsnzjYk.ttf"},
//     {fontWeight: 300,src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf"},
//     {fontWeight: 300,fontStyle: "italic",src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TjARc9AMX6lJBP.ttf"},
//     {fontStyle: "normal", src: "https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf"},
//     {fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf"},
//     {fontWeight: 500, src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmEU9vAx05IsDqlA.ttf"},
//     {fontWeight: 500, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51S7ABc9AMX6lJBP.ttf"},
//     {fontWeight: 700, src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf"},
//     // {fontWeight: 700, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TzBhc9AMX6lJBP.ttf"},
//     // {fontWeight: 900, src: "https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmYUtvAx05IsDqlA.ttf"},
//     // {fontWeight: 900, fontStyle: "italic", src: "https://fonts.gstatic.com/s/roboto/v30/KFOjCnqEu92Fr1Mu51TLBBc9AMX6lJBP.ttf"},
//   ],
// });
// Font.register({
//   family: 'Montserrat',
//   fonts: [
//     {fontWeight: 100, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Uw-Y3tcoqK5.ttf"},
//     {fontWeight: 200, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf"},
//     {fontWeight: 300, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCs16Ew-Y3tcoqK5.ttf"},
//     {fontWeight: "normal", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf"},
//     {fontWeight: 500, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtZ6Ew-Y3tcoqK5.ttf"},
//     {fontWeight: 600, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf"},
//     {fontWeight: 700, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCuM70w-Y3tcoqK5.ttf"},
//     // {fontWeight: 800, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr70w-Y3tcoqK5.ttf"},
//     // {fontWeight: 900, src: "https://fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvC70w-Y3tcoqK5.ttf"},
//     {fontWeight: 100, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R8aX9-p7K5ILg.ttf"},
//     {fontWeight: 200, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR9aX9-p7K5ILg.ttf"},
//     {fontWeight: 300, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq_p9aX9-p7K5ILg.ttf"},
//     {fontWeight: "normal", fontStyle: "italic", src:"https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq6R9aX9-p7K5ILg.ttf"},
//     {fontWeight: 500, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq5Z9aX9-p7K5ILg.ttf"},
//     // {fontWeight: 600, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq3p6aX9-p7K5ILg.ttf"},
//     // {fontWeight: 700, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jq0N6aX9-p7K5ILg.ttf"},
//     // {fontWeight: 800, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqyR6aX9-p7K5ILg.ttf"},
//     // {fontWeight: 900, fontStyle: "italic", src: "https://fonts.gstatic.com/s/montserrat/v25/JTUFjIg1_i6t8kCHKm459Wx7xQYXK0vOoz6jqw16aX9-p7K5ILg.ttf"}
//   ],
// });
function getStylesPDF(
  isPDF,
  sectionSpacing,
  lineSpacing,
  fontSize,
  fontWeight
) {
  let obj = {
    "#resume": {
      paddingTop: "0.5in",
      paddingBottom: "0.5in",
      paddingLeft: "0.5in",
      paddingRight: "0.5in",
    },
    ".header": {
      display: "flex",
      flexDirection: "row",
      marginBottom: sectionSpacing * 5 + "px",
    },
    "#identification": {
      fontFamily: "Poppins",
      flex: 2,
    },
    "#name": {
      fontFamily: "Poppins",
      fontSize: 20 * fontSize + "px",
      fontWeight: 600,
      color: "black",
    },
    "#title": {
      fontFamily: "Poppins",
      fontSize: 18 * fontSize + "px",
      fontWeight: 200,
    },
    "#communication": {
      flex: 1,
      fontFamily: "Poppins",
      fontSize: 14 * fontSize + "px",
      fontWeight: 200,
    },
    ".jobHeader": {
      display: "flex",
      flexDirection: "row",
      marginBottom: "0px",
      marginTop: "10px",
      paddingTop: "0px",
      paddingBottom: "0px",
    },
    ".jobTitle": {
      fontFamily: "Poppins",
      fontSize: 14 * fontSize + "px",
      fontWeight: 500,
      flex: 2,
      textAlign: "left",
      marginTop: "0px",
    },
    ".jobDate": {
      fontFamily: "Poppins",
      fontSize: 14 * fontSize + "px",
      fontWeight: 300,
      flex: 1,
      textAlign: "right",
      color: "gray",
      marginTop: "0px",
    },
    ".jobCompany": {
      fontFamily: "Poppins",
      fontSize: 14 * fontSize + "px",
      fontWeight: 300,
      color: "gray",
      marginTop: "0px",
      marginBottom: "10px",
    },
    // jobDescription: {
    //   fontFamily: 'Poppins',
    //   fontSize: isPDF ? 12*fontSize+'px' : 12*fontSize+'px',
    //   textAlign: 'justify',
    // },
    ".skills": {
      // marginBottom: isPDF ? sectionSpacing*5+"px" : sectionSpacing*5+"px",
    },
    ".hardSkills": {},
    ".hardSkillsTitle": {
      fontFamily: "Poppins",
      fontWeight: 300,
      fontSize: "12px",
    },
    ".hardSkillsItems": {
      fontFamily: "Poppins",
      fontWeight: 300,
      fontSize: "12px",
    },
    ".softSkills": {
      marginBottom: "10px",
    },
    ".softSkillsTitle": {
      fontFamily: "Poppins",
      fontWeight: 300,
      fontSize: "12px",
    },
    ".softSkillsItems": {
      fontFamily: "Poppins",
      fontWeight: 300,
      fontSize: "12px",
    },
    ".educationSection": {
      fontFamily: "Poppins",
      display: "flex",
      flexDirection: "row",
      marginBottom: sectionSpacing * 5 + "px",
    },
    ".educationTitle": {
      fontFamily: "Poppins",
      fontSize: 14 * fontSize + "px",
      fontWeight: 400,
      flex: 3,
      textAlign: "left",
    },
    ".educationDate": {
      fontFamily: "Poppins",
      fontSize: 14 * fontSize + "px",
      fontWeight: isPDF ? 300 : 300,
      flex: 1,
      textAlign: "right",
      color: "gray",
    },
    ".sectionTitle": {
      fontFamily: "Poppins",
      fontSize: 14 * fontSize + "px",
      fontWeight: 600,
      color: "black",
      marginTop: "20px",
      marginBottom: "20px",
      borderBottom: "2px dashed rgba(200, 200, 200)",
      letterSpacing: "3px",
    },
  };
  return obj;
}
const getStyles = (
  isPDF,
  sectionSpacing,
  lineSpacing,
  fontSize,
  fontWeight
) => ({
  resume: {
    paddingTop: isPDF ? "0.5in" : "0.5in",
    paddingBottom: isPDF ? "0.5in" : "0.5in",
    paddingLeft: isPDF ? "0.5in" : "0.5in",
    paddingRight: isPDF ? "0.5in" : "0.5in",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    marginBottom: isPDF ? sectionSpacing * 5 + "px" : sectionSpacing * 5 + "px",
  },
  identification: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    flex: 2,
  },
  name: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 20 * fontSize + "px" : 20 * fontSize + "px",
    fontWeight: isPDF ? 600 : 700,
    color: "black",
  },
  title: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 18 * fontSize + "px" : 18 * fontSize + "px",
    fontWeight: isPDF ? 200 : 200,
  },
  communication: {
    flex: 1,
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: isPDF ? 200 : 200,
  },
  summary: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 12 * fontSize + "px" : 12 * fontSize + "px",
    marginBottom: isPDF ? sectionSpacing * 5 + "px" : sectionSpacing * 5 + "px",
  },
  jobHeader: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "0px",
    marginTop: "10px",
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  jobTitle: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: isPDF ? 500 : 500,
    flex: 2,
    textAlign: "left",
    marginTop: "0px",
  },
  jobDate: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: isPDF ? 300 : 300,
    flex: 1,
    textAlign: "right",
    color: "gray",
    marginTop: "0px",
  },
  jobCompany: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: isPDF ? 300 : 300,
    color: "gray",
    marginTop: "0px",
    marginBottom: "10px",
  },
  // jobDescription: {
  //   fontFamily: isPDF ? 'Poppins' : 'Poppins',
  //   fontSize: isPDF ? 12*fontSize+'px' : 12*fontSize+'px',
  //   textAlign: 'justify',
  // },
  skills: {
    // marginBottom: isPDF ? sectionSpacing*5+"px" : sectionSpacing*5+"px",
  },
  hardSkills: {},
  hardSkillsTitle: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontWeight: 300,
    fontSize: isPDF ? "14px" : "12px",
  },
  hardSkillsItems: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontWeight: 300,
    fontSize: isPDF ? "14px" : "12px",
  },
  softSkills: {
    marginBottom: "10px",
  },
  softSkillsTitle: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontWeight: 300,
    fontSize: isPDF ? "14px" : "12px",
  },
  softSkillsItems: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontWeight: 300,
    fontSize: isPDF ? "14px" : "12px",
  },
  educationSection: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    display: "flex",
    flexDirection: "row",
    marginBottom: isPDF ? sectionSpacing * 5 + "px" : sectionSpacing * 5 + "px",
  },
  educationTitle: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: 400,
    flex: 3,
    textAlign: "left",
  },
  educationDate: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: isPDF ? 300 : 300,
    flex: 1,
    textAlign: "right",
    color: "gray",
  },
  sectionTitle: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: 600,
    color: "black",
    marginTop: "20px",
    marginBottom: "20px",
    borderBottom: "2px dashed rgba(200, 200, 200)",
    letterSpacing: "3px",
  },
  // p: {
  //   fontFamily: isPDF ? 'Poppins' : 'Poppins',
  //   fontSize: isPDF ? '14px' : 12*fontSize+'px',
  //   fontWeight: 300,
  //   marginTop: "0px",
  //   marginBottom: "0px",
  //   display: 'inherit'
  // },
  ul: {
    textAlign: "justify",
    marginTop: "5px",
    marginBottom: "5px",
  },
  ol: {
    textAlign: "justify",
    marginTop: "5px",
    marginBottom: "5px",
  },
  li: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? "14px" : "14px",
    fontWeight: 300,
    textAlign: "justify",
    paddingRight: "40px",
  },
  [".summary-content"]: {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 100,
    textAlign: "justify",
  },
  // '.summary-content-paragraph p': {
  //   fontFamily: isPDF ? 'Poppins' : 'Poppins',
  //   fontSize: isPDF ? 12*fontSize+'px' : 12*fontSize+'px',
  //   textAlign: 'justify',
  // },
  // '.summary-content-list ul': {
  //   fontFamily: isPDF ? 'Poppins' : 'Poppins',
  //   fontSize: isPDF ? 12*fontSize+'px' : 12*fontSize+'px',
  //   textAlign: 'justify',
  // },
  // '.summary-content-list-item li': {
  //   fontFamily: isPDF ? 'Poppins' : 'Poppins',
  //   fontSize: isPDF ? 12*fontSize+'px' : 12*fontSize+'px',
  //   textAlign: 'justify',
  // },
  // '.job-description': {
  //   width: "7in",
  // },
  [".job-description-paragraph"]: {
    fontFamily: isPDF ? "Poppins" : "Poppins",
    fontSize: isPDF ? 14 * fontSize + "px" : 14 * fontSize + "px",
    fontWeight: "normal",
    textAlign: "justify",
  },
  // '.job-description-list ul': {
  //   margin: "0px",
  // },
  // '.job-description-list-item li': {
  //   fontFamily: isPDF ? 'Poppins' : 'Poppins',
  //   fontSize: isPDF ? 20*fontSize+'px' : 12*fontSize+'px',
  // },
});

const ResumeContent = ({
  contactInfo,
  summary,
  educationInfo,
  employementInfo,
  resumeScreens,
  otheresResumeData,
  isPDF,
  sectionSpacing,
  lineSpacing,
  fontSize,
  fontWeight,
  enabledSections,
  styles,
  isEdit,
}) => {
  return (
    <>
      <div id="resume" style={styles.resume}>
        <div id="header" className=".section" style={styles.header}>
          <div id="identification" style={styles.identification}>
            <div id="name" style={styles.name}>
              {contactInfo.firstName} {contactInfo.lastName}
            </div>
            <div id="title" style={styles.title}></div>
          </div>
          <div id="communication" style={styles.communication}>
            <div id="address">
              {contactInfo.city ? contactInfo.city : ""}
              {contactInfo.state ? "," : ""} {contactInfo.state}
              {contactInfo.country ? "," : ""}{" "}
              {contactInfo.country ? contactInfo.country : ""}{" "}
              {contactInfo.postalCode ? contactInfo.postalCode : ""}
            </div>
            <div id="email">{contactInfo.email ? contactInfo.email : ""}</div>
            <div id="phone">
              {contactInfo.phoneNumber ? contactInfo.phoneNumber : ""}
            </div>
            <div id="profile">
              {contactInfo.onlineProfile1 ? contactInfo.onlineProfile1 : ""}
            </div>
          </div>
        </div>
        {summary && summary != "<p></p>" ? (
          <>
            <div id="summary" className=".section" style={styles.sectionTitle}>
              SUMMARY
            </div>
            {isPDF ? (
              <div
                className=".summary-content"
                style={styles.summary}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(summary, {
                    allowedAttributes: {
                      p: ["class", "style"],
                      ul: ["class", "style"],
                      li: ["class", "style"],
                    },
                    allowedClasses: {
                      p: ["summary-content-paragraph"],
                      ul: ["summary-content-list"],
                      li: ["summary-content-list-item"],
                    },
                    transformTags: {
                      p: sanitizeHtml.simpleTransform("p", {
                        class: "summary-content-paragraph",
                      }),
                      ul: sanitizeHtml.simpleTransform("ul", {
                        class: "summary-content-list",
                      }),
                      li: sanitizeHtml.simpleTransform("li", {
                        class: "summary-content-list-item",
                      }),
                    },
                  }),
                }}
              ></div>
            ) : (
              <div
                className=".summary-content"
                style={styles.summary}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(summary, {
                    allowedAttributes: {
                      p: ["class", "style"],
                      ul: ["class", "style"],
                      li: ["class", "style"],
                    },
                    allowedClasses: {
                      p: ["summary-content-paragraph"],
                      ul: ["summary-content-list"],
                      li: ["summary-content-list-item"],
                    },
                    transformTags: {
                      p: sanitizeHtml.simpleTransform("p", {
                        class: "summary-content-paragraph",
                      }),
                      ul: sanitizeHtml.simpleTransform("ul", {
                        class: "summary-content-list",
                      }),
                      li: sanitizeHtml.simpleTransform("li", {
                        class: "summary-content-list-item",
                      }),
                    },
                  }),
                }}
              ></div>
            )}
          </>
        ) : (
          <></>
        )}
        {checkForEmpty(employementInfo.employment) ? (
          <>
            <div
              id="experience"
              className=".section"
              style={styles.sectionTitle}
            >
              EXPERIENCE
            </div>
            <>
              {isEdit ? (
                <ExpereinceDraggable
                  experienceData={employementInfo.employment}
                />
              ) : (
                <>
                  {employementInfo.employment.map((item, i) => (
                    <div className="job">
                      <div style={styles.jobHeader}>
                        <div style={styles.jobTitle}>
                          {item.designation ? item.designation : ""}
                        </div>
                        <div style={styles.jobDate}>
                          {item.from ? item.from + " " : ""}
                          {(item.to || item.iscurrentJob) && item.from
                            ? " - "
                            : ""}
                          {item.to
                            ? item.to
                            : item.iscurrentJob
                            ? "Present"
                            : ""}
                        </div>
                      </div>
                      <div style={styles.jobCompany}>
                        {item.company ? item.company : ""}
                        {item.location ? ", " + item.location : ""}
                      </div>
                      {item.jobDescription &&
                      item.jobDescription !== "<p></p>" ? (
                        <>
                          {isPDF ? (
                            <div
                              className="job-description"
                              style={styles.jobDescription}
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(item.jobDescription, {
                                  allowedAttributes: {
                                    p: ["class", "style"],
                                    ul: ["class", "style"],
                                    li: ["class", "style"],
                                  },
                                  allowedClasses: {
                                    p: ["job-description-paragraph"],
                                    ul: ["job-description-list"],
                                    li: ["job-description-list-item"],
                                  },
                                  transformTags: {
                                    p: sanitizeHtml.simpleTransform("p", {
                                      class: "job-description-paragraph",
                                    }),
                                    ul: sanitizeHtml.simpleTransform("ul", {
                                      class: "job-description-list",
                                    }),
                                    li: sanitizeHtml.simpleTransform("li", {
                                      class: "job-description-list-item",
                                    }),
                                  },
                                }),
                              }}
                            ></div>
                          ) : (
                            <div
                              className="job-description"
                              style={styles.jobDescription}
                              dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(item.jobDescription, {
                                  allowedAttributes: {
                                    p: ["class", "style"],
                                    ul: ["class", "style"],
                                    li: ["class", "style"],
                                  },
                                  allowedClasses: {
                                    p: ["job-description-paragraph"],
                                    ul: ["job-description-list"],
                                    li: ["job-description-list-item"],
                                  },
                                  transformTags: {
                                    p: sanitizeHtml.simpleTransform("p", {
                                      class: "job-description-paragraph",
                                    }),
                                    ul: sanitizeHtml.simpleTransform("ul", {
                                      class: "job-description-list",
                                    }),
                                    li: sanitizeHtml.simpleTransform("li", {
                                      class: "job-description-list-item",
                                    }),
                                  },
                                }),
                              }}
                            ></div>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                      {item.softSkills?.length || item.hardSkills?.length ? (
                        <div style={styles.skills}>
                          {item.hardSkills?.length ? (
                            <div style={styles.hardSkills}>
                              <span style={styles.hardSkillsTitle}>
                                Hard Skills:{" "}
                              </span>
                              <span style={styles.hardSkillsItems}>
                                {item.hardSkills.join(", ")}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                          {item.softSkills?.length ? (
                            <div style={styles.softSkills}>
                              <span style={styles.softSkillsTitle}>
                                Soft Skills:{" "}
                              </span>
                              <span style={styles.softSkillsItems}>
                                {item.softSkills.join(", ")}
                              </span>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ))}
                </>
              )}
            </>
          </>
        ) : (
          <></>
        )}
        {checkForEmpty(educationInfo.education) ? (
          <>
            <div
              id="experience"
              className=".section"
              style={styles.sectionTitle}
            >
              EDUCATION
            </div>
            {educationInfo.education.map((item) => (
              <div style={styles.educationSection}>
                <span style={styles.educationTitle}>
                  {item.field}
                  {item.field && item.school ? " @ " : ""}
                  {item.school ? item.school : ""}{" "}
                </span>
                <span style={styles.educationDate}>
                  {item.from}
                  {item.to && item.from ? " - " : ""} {item.to ? item.to : ""}
                </span>
              </div>
            ))}
          </>
        ) : (
          <></>
        )}

        {resumeScreens.map((item) => (
          <>
            <div>
              {otheresResumeData &&
              typeof otheresResumeData[item.id] === "string" &&
              otheresResumeData[item.id] ? (
                <>
                  <div id="other" style={styles.sectionTitle}>
                    {item.id}
                  </div>
                  {isPDF ? (
                    <div style={styles.otherData}>
                      {otheresResumeData[item.id]}
                    </div>
                  ) : (
                    <div
                      style={styles.otherData}
                      dangerouslySetInnerHTML={{
                        __html: otheresResumeData[item.id],
                      }}
                    ></div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        ))}
      </div>
    </>
  );
};

const Template1 = ({
  downloadLinkRef,
  isEdit,
  sectionSpacing,
  lineSpacing,
  fontSize,
  fontWeight,
  enabledSections,
  fileName,
  setinstanceLoader,
}) => {
  const {
    contactInfo,
    summary,
    educationInfo,
    employementInfo,
    resumeScreens,
  } = useSelector((state) => state.resumeOptimizer);
  const otheresResumeData = useSelector(
    (state) => state.resumeOptimizer.otheresResumeData
  );
  useEffect(() => {
    if (
      employementInfo &&
      employementInfo.employment &&
      employementInfo.employment.length > 0
    ) {
      employementInfo.employment.forEach((item) => {
        const clean = sanitizeHtml(item.jobDescription, {
          allowedAttributes: {
            p: ["class", "style"],
            ul: ["class", "style"],
            li: ["class", "style"],
          },
          allowedClasses: {
            p: ["job-description-paragraph"],
            ul: ["job-description-list"],
            li: ["job-description-list-item"],
          },
          transformTags: {
            p: sanitizeHtml.simpleTransform("p", {
              class: "job-description-paragraph",
            }),
            ul: sanitizeHtml.simpleTransform("ul", {
              class: "job-description-list",
            }),
            li: sanitizeHtml.simpleTransform("li", {
              class: "job-description-list-item",
            }),
          },
        });
        console.log(clean);
      });
    }
  }, []); // empty dependency array ensures this runs once on component mount
  // Convert the ResumeContent component to a static HTML string
  const resumeHtml = renderToStaticMarkup(
    <ResumeContent
      contactInfo={contactInfo}
      summary={summary}
      educationInfo={educationInfo}
      employementInfo={employementInfo}
      resumeScreens={resumeScreens}
      otheresResumeData={otheresResumeData}
      isPDF={true}
      sectionSpacing={sectionSpacing}
      lineSpacing={lineSpacing}
      fontSize={fontSize}
      fontWeight={fontWeight}
      enabledSections={enabledSections}
      styles={getStyles(
        true,
        sectionSpacing,
        lineSpacing,
        fontSize,
        fontWeight
      )}
    />
  );
  const [instance, updateInstance] = usePDF({
    document: (
      <Document>
        <Page size="LETTER" dpi={100}>
          <Html
            stylesheet={getStylesPDF(
              true,
              sectionSpacing,
              lineSpacing,
              fontSize,
              fontSize
            )}
            resetStyles={false}
          >
            {resumeHtml}
          </Html>
        </Page>
      </Document>
    ),
  });

  useEffect(() => {
    if (setinstanceLoader && typeof setinstanceLoader == "function") {
      setinstanceLoader(instance.loading);
    }
  }, [instance.loading]);

  if (instance.loading) return <div>Loading ...</div>;

  if (instance.error) return <div>Something went wrong: {instance.error}</div>;
  return (
    <div>
      {/* Display the content on the web page */}
      <ResumeContent
        contactInfo={contactInfo}
        summary={summary}
        educationInfo={educationInfo}
        employementInfo={employementInfo}
        resumeScreens={resumeScreens}
        otheresResumeData={otheresResumeData}
        isPDF={false}
        sectionSpacing={sectionSpacing}
        lineSpacing={lineSpacing}
        fontSize={fontSize}
        fontWeight={fontWeight}
        enabledSections={enabledSections}
        styles={getStyles(
          false,
          sectionSpacing,
          lineSpacing,
          fontSize,
          fontWeight
        )}
        isEdit={isEdit}
      />
      {/* <PDFViewer width={"100%"} height={"1000px"}>
        <Document>
          <Page size="LETTER">
            <Html stylesheet={getStyles(true)}>{resumeHtml}</Html>
          </Page>
        </Document>
      </PDFViewer> */}
      <a
        ref={downloadLinkRef}
        href={instance.url}
        download={`${fileName}.pdf`}
        style={{ display: "none" }}
      ></a>
    </div>
  );
};

export default Template1;
