/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Layout, Row, Col, Progress } from "antd";
import moment from "moment";
import "./style.scss";
import history from "../../routes/History";
import { useDispatch, useSelector } from "react-redux";
import UploadResumeCard from "./uploadResumeCard";
import UploadStatusCard from "./uploadStatusCard";
import UploadAtsView from "./uploadAtsView";
import { acceptOverLayRequest } from "../../store/dashBoard/dashboardActions";
import UploadRegisterLastScreen from "./uploadRegisterLastScreen";
import { saveUSerSubsRequest } from "../../store/newRegister/registerActions";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.svg";

const UploadResume = (props) => {
  const { parseResume } = props;
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const [showAts, setshowAts] = useState(false);
  const [registerLastScreen, setRegisterLastScreen] = useState(false);
  const [showResUploadCard, setShowResUploadCard] = useState(true);
  const [showResUploadStatusCard, setShowResUploadStatusCard] = useState(false);
  const loginState = useSelector((state) => state.login);
  const { uploadResumeSuccess, preUpladData } = useSelector(
    (state) => state.register
  );

  const contactInfo = useSelector((state) => state.dashboard.contactInfo);
  const employementInfo = useSelector(
    (state) => state.dashboard.employementInfo
  );
  const educationInfo = useSelector((state) => state.dashboard.educationInfo);
  const skillsTaxanomy = useSelector(
    (state) => state.dashboard.skillsForAtsView
  );
  const ExperienceSummaryDetails = useSelector(
    (state) => state.dashboard?.resumeParseData?.ExperienceSummaryDetails
  );

  const onFileFinish = (fileRes, fName, rawFile) => {
    setShowResUploadCard(false);
    setShowResUploadStatusCard(true);
    parseResume(fileRes, fName, rawFile);
  };

  const handleChange = (file) => {
    setFileName(file.name);
    const resumeName = file.name;
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (innfile) => {
      let fileSpl = innfile.target.result.split("base64,");
      const resBinary = { resumeBinary: fileSpl[1] };
      !Object.keys(resBinary).length
        ? null
        : onFileFinish(resBinary, resumeName, new Blob([file], { type: file.type }));
    };
  };

  useEffect(() => {
    let total = 0;
    employementInfo.employment.map((item) => {
      if (item.from) {
        const d1 = moment(item.from);
        const d2 = item.to ? moment(item.to) : moment();
        const diffTime = Math.abs(d2 - d1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        total = total + diffDays;
      }
    });
  }, [employementInfo]);

  useEffect(() => {
    if (uploadResumeSuccess) {
      setTimeout(() => {
        setShowResUploadStatusCard(false);
        setshowAts(true);
      }, 2000);
    }
  }, [uploadResumeSuccess]);

  useEffect(() => {
    if (preUpladData !== "") {
      setShowResUploadStatusCard(false);
      setShowResUploadCard(false);
      setshowAts(true);
    }
  }, [preUpladData]);

  const handleNextClick = () => {
    return () => {
      if (showResUploadStatusCard) {
      } else {
        history.push("/onBoarding");
        setRegisterLastScreen(false);
        dispatch(
          saveUSerSubsRequest({
            tier_id: loginState.userData.tier_id,
            subscription_expiry: loginState.userData.subscription_expiry,
            user_credits: loginState.userData.user_credits,
          })
        );
        dispatch(
          acceptOverLayRequest({
            key: "overlay_seen",
            value: "true",
          })
        );
      }
    };
  };

  return (
    <Layout className="upload-container">
      <Row justify="center">
        <Col span={18} className="uploadContainer-inner">
          {showResUploadStatusCard && <UploadStatusCard filename={fileName} />}
          {showResUploadCard && <UploadResumeCard handleClick={handleChange} />}
          {showAts && (
            <UploadAtsView
              name={contactInfo.formattedName}
              employInfo={employementInfo.employment}
              skills={skillsTaxanomy}
              education={educationInfo}
              ExperienceSummaryDetails={ExperienceSummaryDetails}
            />
          )}
          {registerLastScreen && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p className="finalregistertitle" style={{ marginTop: "70px" }}>
                Here’s what you can do, now that we have extracted your data
              </p>
              <UploadRegisterLastScreen />
              <p
                className="finalregistertitle"
                style={{ fontSize: "20px", marginTop: "20px" }}
              >
                However we need a couple of confirmations to make this a great
                experience
              </p>
            </div>
          )}
          <div onClick={handleNextClick()} style={{ cursor: "pointer" }}>
            {!showResUploadStatusCard && !showResUploadCard && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                {/* <div className="next" htmlType="submit">
                  Nextss
                </div> */}
                <button type="button" className="nxtButton" htmlType="submit">
                  <>
                    Next <img src={WhiteArrowLeft} />
                  </>
                </button>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default UploadResume;
