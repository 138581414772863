const devBaseURLs = {
  authPath: process.env.REACT_APP_BASE_URL,
};

const baseUrl =
  process.env.REACT_APP_ENV === "production"
    ? "https://server.unnlock.ai"
    : process.env.REACT_APP_BASE_URL;

const devPaths = {
  login: {
    userLogin: `${devBaseURLs.authPath}/user-service/user/user-login`,
  },
  refreshLogin: {
    refreshLoginUrl: `${devBaseURLs.authPath}/user-service/user/user-refreshlogin`,
    forgotPassUrl: `${devBaseURLs.authPath}/email/send-link`,
    resetPassUrl: `${devBaseURLs.authPath}/email/verify`,
  },
  userRestoreCred: {
    newForgotPassUrl: (email) =>
      `${devBaseURLs.authPath}/user-service/user/user-forgetpassword?email=${email}`,
    newResetPassUrl: `${devBaseURLs.authPath}/user/user-confirmforgetpassword`,
  },
  resumeParse: {
    resumeParseData: (resumeId) =>
      `${devBaseURLs.authPath}/optimizer-service/resume/${resumeId}`,
    extractSkills: `${devBaseURLs.authPath}/isme-service/skills/extract`,
    companiesList: (companyName, title) =>
      `${devBaseURLs.authPath}/company/search/list?company_name=${companyName}&title=${title}`,
    companySearch: () =>
      `${devBaseURLs.authPath}/search-service/v2/company_match`,
  },
  registration: {
    uploadCV: (email, cvName) =>
      `${devBaseURLs.authPath}/user-service/user/upload-file/file-name=${email}/${cvName}`,
    registerUser: `${devBaseURLs.authPath}/user-service/user/create-user`,
    subscriptionUpdationUrl: `${devBaseURLs.authPath}/subscription-service/create`,
    getUserInformation: `${devBaseURLs.authPath}/user-service/user/get-user`,
    getUserIndustries: `${devBaseURLs.authPath}/job-service/user_industries`,
    getSubsCount: `${devBaseURLs.authPath}/subscription-service/fetch`,
    applyCoupon: `${devBaseURLs.authPath}/subscription-service/subscribe`,
    socialSignUp: `${devBaseURLs.authPath}/user-service/user/social-signup`,
    getAllSocs: `${devBaseURLs.authPath}/search-service/v2/soc-layout`,
    emailVerify: `${devBaseURLs.authPath}/verify-service/initiate-mail-verification`,
  },
  social: {
    socialUrl: `${devBaseURLs.authPath}/user-service/user/social-signup`,
  },
  employmentDetail: {
    employmentDetailUrl: (userId) =>
      `${devBaseURLs.authPath}/parser-service/parser/unnlock/${userId}?html=true`,
    newEmploymentDetailUrl: (userId) =>
      `${devBaseURLs.authPath}/optimizer-service/parse/${userId}`,
    sendFileToS3 : (userId) => 
      `https://server.unnlock.ai/upload-file/${userId}`,
    checkEmail : (userEmail) =>
      `${devBaseURLs.authPath}/verify-service/check_email?email=${userEmail}`
  },
  favourite: {
    favouriteUrl: (operation, companyId) =>
      `${devBaseURLs.authPath}/user-service/user/favourite/${operation}/${companyId}`,
  },
  companyDetails: {
    companyDetailUrl: (companyId) =>
      `${devBaseURLs.authPath}/company/search/${companyId}`,
    getCompaniesByLocation: () =>
      `${devBaseURLs.authPath}/search-service/v2/company_by_location`,
    getNearestCompany: () =>
      `${devBaseURLs.authPath}/search-service/v2/company/search`,
    getAutoComplete: (value) =>
      `${devBaseURLs.authPath}/search-service/v2/company?name=${value}`,
    updateTopCompany: (id) =>
      `${devBaseURLs.authPath}/updater-service/matching-company-update/${id}`,
    getCompanyDeatils: (id) =>
      `${devBaseURLs.authPath}/search-service/v2/company_details/${id}`,
    getDashboardCompanyDeatils: () =>
      `${devBaseURLs.authPath}/search-service/v2/company/search`,
    searchBySkills: `${devBaseURLs.authPath}/search-service/v2/company_by_skills`,
    getSocsSkillsArrayUrl: (skill, id) =>
      `${devBaseURLs.authPath}/search-service/v2/soc-skills/${skill}/${id}`,
    getMatchIndustryUrl: () =>
      `${devBaseURLs.authPath}/job-service/match_industries`,
    getCompanyLocations: (id) =>
      `${devBaseURLs.authPath}/location-service/geolocations/${id}`,
  },
  personalityTest: {
    getQuestions: () =>
      `${devBaseURLs.authPath}/quiz-service/quiz/get-questions`,
    getResults: (id) =>
      `${devBaseURLs.authPath}/quiz-service/quiz/result/${id}`,
    submitResults: () => `${devBaseURLs.authPath}/quiz-service/quiz/save`,
  },
  resumeOptimizer: {
    getResumeVaultsUrl: () =>
      `${devBaseURLs.authPath}/vault-service/vault/get-resumes`,
    putResumeVaultsUrl: (resumeData) =>
      `${devBaseURLs.authPath}/vault-service/vault/update-resume-notes/${resumeData}`,
    syncSkillsForJobMatch: (resumeid) =>
      `${devBaseURLs.authPath}/optimizer-service/sync-employment/${resumeid}`,
    cloneResume: (resumeid) =>
      `${devBaseURLs.authPath}/vault-service/vault/unnlock/clone/${resumeid}`,
    resumeUrls: (resumeid) =>
      `${devBaseURLs.authPath}/optimizer-service/resume/${resumeid}`,
    putResumesUrl: (resumeid, label) =>
      `${devBaseURLs.authPath}/updater-service/update/${label}/${resumeid}`,
    dowloadInPdf: (fileName, format) =>
      `https://3kjn7f3hu7.execute-api.us-east-2.amazonaws.com/qa/download/${format}/${fileName}`,
    deleteResume: (id) =>
      `${devBaseURLs.authPath}/vault-service/vault/delete-resume/${id}`,
    getSuggestedSkills: (label, id) =>
      `${devBaseURLs.authPath}/search-service/v2/soc-details/${label}/${id}`,
  },
  ProfileDetails: {
    putProfilePic: `${devBaseURLs.authPath}/user-service/user/update`,
    getProfilePicUrl: (fileType, fileName) =>
      `${devBaseURLs.authPath}/user-service/user/upload/${fileType}/${fileName}`,
    addProfilePic: (url) => `${url}`,
    uploadProfilePicUrl: `${devBaseURLs.authPath}/image-service/upload-pic`,
    getSubscription: `${devBaseURLs.authPath}/user-service/user/get-subscriptions`,
    createCustomer: `${devBaseURLs.authPath}/payment-service/stripe/customer`,
    createSubscription: (customerId, priceId) =>
      `${devBaseURLs.authPath}/payment-service/create-subscription/${customerId}/${priceId}`,
    cancelSubscription: (subsId) =>
      `${devBaseURLs.authPath}/payment-service/cancel-subscription/${subsId}`,
    getCardDetails: (subpaumentId) =>
      `${devBaseURLs.authPath}/payment-service/stripe/card/${subpaumentId}`,
    changePassword: `${devBaseURLs.authPath}/user-service/user/password-change`,
    forgotPassword: (userName) =>
      `${devBaseURLs.authPath}/user-service/user/user-forgetpassword/${userName}`,
    getPaymentMethods: (custId) =>
      `${devBaseURLs.authPath}/payment-service/stripe/card-list/${custId}`,
    attachPaymentMethod: (custId, payMethod) =>
      `${devBaseURLs.authPath}/payment-service/stripe/attach-card/${custId}/${payMethod}`,
    detachCard: (payMethod) =>
      `${devBaseURLs.authPath}/payment-service/stripe/detach-card/${payMethod}`,
    updateSubTonewPayment: (subId, payID) =>
      `${devBaseURLs.authPath}/payment-service/stripe/update-subscription/${subId}/${payID}`,
    OverLayAccept: `${devBaseURLs.authPath}/user-service/user/update`,
    saveCompanyDatils: `${devBaseURLs.authPath}/user-service/user/update`,
    getCompanyFilteres: `${devBaseURLs.authPath}/search-service/v2/company_filters`,
  },
  toolsDetails: {
    postMatchJob: (id) => `${devBaseURLs.authPath}/job-service/match_job/${id}`,
    getComponsation: (experience, designation) =>
      `${devBaseURLs.authPath}/isme-service/compensation/${experience}?query=${designation}`,
    getSkills: (title) =>
      `${devBaseURLs.authPath}/isme-service/skills/recommendation?title=${title}`,
    getSkillsFromDesig: (title) =>
      `${devBaseURLs.authPath}/isme-service/skills-by-title?title_name=${title}`,
    postCompURLs: (resumeId) =>
      `${devBaseURLs.authPath}/company-url-update/${resumeId}`,
    getOpenJobs: `${devBaseURLs.authPath}/job-board/get-jobs`,
    getChatBotAnswers: (useOld) =>
      useOld
        ? `https://server.unnlock.ai/ai-service/unnlock/prompt-old`
        : `https://server.unnlock.ai/ai-service/unnlock/prompt`,
    getOpenJobChatBotAnswers: `https://server.unnlock.ai/ai-service/unnlock/oj-chat`,
    getChatBotQuestions: `${devBaseURLs.authPath}/ai-service/questions`,
    getJobMatchFit: `https://server.unnlock.ai/ai-service/unnlock/improve`,
    getAiSearchedJobs: `https://server.unnlock.ai/ai-service/unnlock/ub-job-match`,
    getCoverLetter: `${devBaseURLs.authPath}/ai-service/unnlock/generate-cover`,
  },
  jobMatch: {
    jobMatchResult: (resId) => `${devBaseURLs.authPath}/job-service/match_job`,
    saveJobMatchResult: `${devBaseURLs.authPath}/tracker-service/job-tracker`,
    jobMatchFetch: (jobMatchId) =>
      `${devBaseURLs.authPath}/job-service/fetch/${jobMatchId}`,
    deleteJobTrack: `${devBaseURLs.authPath}/tracker-service/job-tracker/delete`,
    updateJobTrack: `${devBaseURLs.authPath}/tracker-service/job-tracker`,
  },
};

const config =
  process.env.REACT_APP_STAGE === "production" ? devPaths : devPaths;

export default {
  ...config,
};
