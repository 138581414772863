/*eslint-disable*/
import React from "react";
import {
  Router,
  Switch,
  Redirect,
  Route,
  // useHistory,
} from "react-router-dom";
import history from "./History";
import NewRegistration from "../containers/NewRegistration";
import EmploymentDetails from "../containers/EmploymentDetails/EmploymentDetailsContainer";
import CompaniesContainer from "../containers/Companies/CompaniesContainer";
import RecruitersContainer from "../containers/Recruiters/RecruitersContainer";
import BlogsContainer from "../containers/Blogs/BlogsContainer";
import CompanyDetails from "../containers/Companies/CompanyDetails/CompanyDetails";
import Profile from "../containers/Profile";
import NewProfile from "../containers/Profile/NewProfile";
import ResetPassword from "../containers/ResetPassword/ResetPasswordContainer";
import MatchToJobPosting from "../containers/Tools/matchjob/MatchToJobPosting";
import EvaluateJob from "../containers/Tools/evaluateJob/EvaluateJob";
import MatchJobResults from "../containers/Tools/MatchJobResults/MatchJobResults";
import SocialRegistrationContainer from "../containers/SocialRegistration/socialRegistrationContainer2";
import Analysis from "../containers/Analysis";
import Landing from "../containers/Landing/Landing";
import DashboardContainer3 from "../containers/Dashboard/DashboardContainer";
import PlansModel from "../containers/NewLogin/plans";
import ForgotPasswordContainer from "../containers/ForgotPassword/ForgotPasswordContainer";
// import NewLogin from "../containers/NewLogin/Login";
import PrivateRoute from "./privateRoute";
import JobMatchContainer from "../containers/JobMatch/JobMatchContainer";
import ResumeVault from "../containers/ResumeVault";
import ResumeOptimizer from "../containers/ResumeOpimizer";
import TipsAndFAQsContainer from "../containers/TipsAndFAQs/TipsAndFAQsContainer";
import OfferEvaluatorConatiner from "../containers/OfferEvaluator/OfferEvaluatorContainer";
import OfferEvaluatorResult from "../containers/OfferEvaluatorResult/OfferEvaluatorResult";
import PersonalityTest from "../containers/PersonalityTest";
import InterestingReadsConatiner from "../containers/InterestingReads/InterestingReadsContainer";
import ICDP from "../containers/ICDProvider";
import RegistrationLastScreen from "../containers/RegistrationLastScreen/RegistrationLastScreen";
import Explore from "../containers/Explore";
import PersonalDashboard from "../containers/PersonalDashboard";
import SubscriptionContainer from "../containers/Profile/subscriptionModel";
import PreUploadResume from "../containers/NewRegistration/preUploadComp";
import Tracker from "../containers/jobTracker";
import OpenJobs from "../containers/OpenJobs";
import Companies from "../containers/OpenJobs/Companies";
import OpenJobsDetailsComp from "../containers/OpenJobs/detailsPage";
import UnnlockChatBot from "../containers/ChatBot";
import NewRegistrationUploadResume from "../containers/NewRegistrationUploadResume";
import Terms from "../containers/TermsAndFAQ/Terms&Policies";
import Policy from "../containers/TermsAndFAQ/Policy";

const Routes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route exact path="/plans">
          <PlansModel />
        </Route>
        <Route exact path="/newRegistration">
          <NewRegistration />
        </Route>
        <Route exact path="/preUpload">
          <PreUploadResume />
        </Route>
        <Route exact path="/newRegistrationUpload">
          <NewRegistrationUploadResume />
        </Route>
        <Route path="/forgotPassword">
          <ForgotPasswordContainer />
        </Route>
        <Route path="/resetpassword">
          <ResetPassword />
        </Route>
        <Route path="/socialRegistration">
          <SocialRegistrationContainer />
        </Route>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/policy">
          <Policy />
        </Route>
        <PrivateRoute component={DashboardContainer3} path="/dashboard" exact />
        <PrivateRoute
          component={EmploymentDetails}
          path="/employmentdetails"
          exact
        />
        <PrivateRoute component={NewProfile} path="/profile" exact />
        <PrivateRoute component={CompaniesContainer} path="/companyMatch" exact />
        <PrivateRoute
          component={RecruitersContainer}
          path="/recruiters"
          exact
        />
        <PrivateRoute component={MatchToJobPosting} path="/tools" exact />
        <PrivateRoute
          component={MatchJobResults}
          path="/matchjobResults"
          exact
        />
        <PrivateRoute component={EvaluateJob} path="/evaluateJob" exact />
        <PrivateRoute component={BlogsContainer} path="/blogs" exact />
        <PrivateRoute component={CompanyDetails} path="/companydetails" exact />
        <PrivateRoute component={Analysis} path="/analysis" exact />
        <PrivateRoute component={JobMatchContainer} path="/jobmatch" exact />
        <PrivateRoute
          component={TipsAndFAQsContainer}
          path="/tipsAndFAQs"
          exact
        />
        <PrivateRoute
          component={OfferEvaluatorConatiner}
          path="/offerEvaluator"
          exact
        />
        <PrivateRoute
          component={PersonalityTest}
          path="/personalityTest"
          exact
        />
        <PrivateRoute
          component={InterestingReadsConatiner}
          path="/interestingReads"
          exact
        />
        <PrivateRoute
          component={OfferEvaluatorResult}
          path="/OfferEvaluatorResult"
          exact
        />
        <PrivateRoute component={ICDP} path="/onBoarding" exact />

        <PrivateRoute
          component={RegistrationLastScreen}
          path="/lastStep"
          exact
        />

        {/* Resume-vault */}
        <PrivateRoute component={ResumeVault} path="/resumeVault" exact />
        {/* Resume-Optimizer */}
        <PrivateRoute
          component={ResumeOptimizer}
          path="/resumeOptimizer"
          exact
        />
        <PrivateRoute component={Explore} path="/explore" exact />

        <PrivateRoute
          component={PersonalDashboard}
          path="/personalDashboard"
          exact
        />
        <PrivateRoute component={Tracker} path="/jobTracker" exact />
        <PrivateRoute
          component={SubscriptionContainer}
          path="/subscriptions"
          exact
        />
        <PrivateRoute
          component={OpenJobsDetailsComp}
          path="/openJobsDetails"
          exact
        />
        <PrivateRoute component={UnnlockChatBot} path="/coachUnnbot" exact />
        <PrivateRoute component={OpenJobs} path="/OpenJobs" exact />
        <PrivateRoute component={Companies} path="/OpenJobComapnies" exact />
        <Redirect from="**" to="/" exact />
      </Switch>
    </Router>
  );
};

export default Routes;
