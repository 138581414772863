import { MenuOutlined } from "@ant-design/icons";
import React, { useEffect } from "react";
import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { resumeUpdateRequest } from "../../../store/resumeOptimizer/resumeOptimizerActions";
import { formatToEmployementData } from "../../../utils/helper";

// data generator
const getItems = (experienceData) =>
  experienceData.map((k, i) => ({
    id: `${i}`,
    content: k,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  // userSelect: "none",
  // padding: grid * 2,
  // margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "white" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "grey" : "white",
  // padding: grid,
  // width: 250,
});

const ExpereinceDraggable = ({ experienceData }) => {
  const [items, setItems] = useState([]);
  const [isDragged, setIsDragged] = useState(false);
  const dispatch = useDispatch();

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const Items = reorder(items, result.source.index, result.destination.index);
    setItems(Items);
    setIsDragged(true);
  }

  useEffect(() => {
    if (items.length && isDragged) CallApi();
  }, [items, isDragged]);

  const renderExperienceCard = (item) => {
    return (
      <div style={{ marginTop: "10px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "13px",
          }}
        >
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", position: "relative" }}>
              <MenuOutlined
                style={{ position: "absolute", top: 3, left: "-20px" }}
              />
              <p
                style={{
                  whiteSpace: "nowrap",
                  marginBottom: "0px",
                  fontWeight: 600,
                }}
              >
                <span>{item.company}</span>
                <span
                  style={{
                    display: item.company && item.location ? "inline" : "none",
                  }}
                >
                  ,{" "}
                </span>
                <span>{item.location}</span>
              </p>
              <div style={{ position: "absolute", right: "0px", top: "0px" }}>
                {item.from}{" "}
                {(item.to || item.iscurrentJob) && item.from ? " - " : null}
                {item.to ? item.to : item.iscurrentJob ? "Present" : null}
              </div>
            </div>
            <i
              style={{
                fontWeight: 500,
                color: "#261C4B",
              }}
            >
              {item.designation}
            </i>
          </div>
        </div>

        {item.jobDescription && item.jobDescription !== "<p></p>" ? (
          <div
            style={{ fontSize: "12px", textAlign: "justify" }}
            dangerouslySetInnerHTML={{ __html: item.jobDescription }}
          ></div>
        ) : null}
        {item.softSkills?.length || item.hardSkills?.length ? (
          <div style={{ marginTop: "-10px" }}>
            {item.hardSkills?.length ? (
              <div style={{ fontSize: "12px" }}>
                <span style={{ fontWeight: 600 }}>Hard Skills : </span>
                <span>{item.hardSkills.join(", ")}</span>
              </div>
            ) : null}
            {item.softSkills?.length ? (
              <div style={{ fontSize: "12px" }}>
                <span style={{ fontWeight: 600 }}>Soft Skills : </span>
                <span>{item.softSkills.join(", ")}</span>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  };

  const getPostData = () => {
    let formData = {
      employment: items
        .map((item) => item.content)
        .map((item) => {
          return {
            ...formatToEmployementData(item),
            PositionHistory: [
              {
                ...formatToEmployementData(item).PositionHistory[0],
                Description: item.jobDescription || "",
                HardSkills: item.hardSkills || [],
                SoftSkills: item.softSkills || [],
                currentStep: item.currentStep || 0,
              },
            ],
          };
        }),
    };
    return formData;
  };

  const CallApi = () => {
    let formData = getPostData();
    dispatch(
      resumeUpdateRequest({
        formData,
        label: "employment-update",
      })
    );
    setIsDragged(false);
  };

  useEffect(() => {
    if (experienceData) {
      setItems(getItems(experienceData));
    }
  }, [experienceData]);

  return (
    <div>
      {items.length ? (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {renderExperienceCard(item.content)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      ) : null}
    </div>
  );
};

export default ExpereinceDraggable;
