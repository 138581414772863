import React from "react";
import "./landingfooter.scss";
import unlockwhitewithoubg from "../../assets/images/landing/unlockwhitewithoubg.svg";
import history from "../../routes/History";
import MiinedButton from "../../components/Button";
import { setShowCookies } from "../../store/Tools/toolsActions";
import { useDispatch } from "react-redux";

const LandingFooter = () => {
  const dispatch = useDispatch();
  return (
    <section className="landng-footer__Section">
      <div className="landing-footer__flexcontainer">
        <div className="footerlogo-address__container">
          <img
            src={unlockwhitewithoubg}
            alt="unnlock"
            style={{ width: "150px" }}
          />
          <p className="footer-lading__date date_section">
            © {new Date().getFullYear()} unnlock. All rights reserved.
          </p>
        </div>

        <div className="title">feedback@unnlock.com</div>

        <div
          style={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            width: "20%",
            justifyContent: "space-between",
          }}
          className="loginBtn"
        >
          <div style={{ width: "48%" }}>
            <MiinedButton
              type="secondayTransparent"
              title="Login"
              onClick={() => {
                history.push("/newRegistration", { data: "fromLoginBtn" });
              }}
            />
          </div>
          <div style={{ width: "48%" }}>
            <MiinedButton
              type="secondary"
              title="SIGN UP"
              onClick={() => {
                dispatch(setShowCookies(true));
                history.push("/newRegistration", { data: "fromSignUpBtn" });
              }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LandingFooter;
