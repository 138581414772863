import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getDashboardComponyDeatailsRequest } from "../../store/componies/action";
import * as ComponiesActions from "../../store/componies/action";
import CommonInput from "./commonInput";
import "./index.scss";
import unnlockNotification from "../../components/notification";

const ConfirmYourCompanyCard = () => {
  const [inputValue, setInputValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const companyList = useSelector((state) => state.componies.autoComplet);
  const companyLoader = useSelector((state) => state.componies.Loader);
  const companyDetails = useSelector(
    (state) => state.componies.dashboardCompanyDetails
  );

  const dispatch = useDispatch();

  const handleChange = (e) => {
    setInputValue(e.target.value);
    dispatch(ComponiesActions.getDashboardComponyDeatailsFailure());
    if (e.target.value) {
      setSuggestions([]);
      dispatch(
        ComponiesActions.companiesAutoCompleteRequest({
          companyName: e.target.value,
        })
      );
    } else {
      setSuggestions([]);
    }
  };

  const handleSelect = (e) => {
    dispatch(
      getDashboardComponyDeatailsRequest({
        // company_name: e.text,
        unnlock_id: e.value,
      })
    );
  };

  useEffect(() => {
    if (Array.isArray(companyList) && companyList.length) {
      setSuggestions(() =>
        companyList.map((item) => {
          return {
            value: item.unnlock_id,
            text: item.company,
            logo_url: item.logo_url,
          };
        })
      );
    }
  }, [companyList]);

  // useEffect(() => {
  //   if (
  //     Array.isArray(dashboardState.topCompanies) &&
  //     dashboardState.topCompanies.length
  //   ) {
  //     const strArr = `${dashboardState.topCompanies[0].name}`.split("");
  //     if (strArr.length >= 5) {
  //       handleChange({
  //         target: {
  //           value: strArr[0] + strArr[1] + strArr[2] + strArr[3] + strArr[4],
  //         },
  //       });
  //     } else {
  //       handleChange({ target: { value: strArr } });
  //     }
  //   }
  // }, [dashboardState.topCompanies]);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  console.log(companyList, "companyList");

  return (
    <div>
      <div className="card_header">
        <div className="left">
          {/* <img src={bulidingIcon} /> */}
          <div className="companyName">
            Confirm your current or most recent company
          </div>
          {/* <span>Based on your resume</span> */}
        </div>
        {companyLoader && (
          <div>
            <Spin indicator={antIcon} />
          </div>
        )}
      </div>
      <div className="ConfirmYourCompanyCard">
        {/* <div className="devider" /> */}
        <div style={{ padding: "10px" }}>
          <div
            style={{ fontSize: "18px", fontWeight: 500, marginBottom: "10px" }}
          >
            Type company name in the search bar then click to select
          </div>
          <CommonInput
            label="Company Name"
            name="companyName"
            placeholder="Enter company name"
            value={inputValue}
            handleChange={handleChange}
          />
        </div>
        <div className="suggestions">
          {companyList?.message ? (
            <h4 style={{ paddingLeft: "10px" }}>{companyList?.message}</h4>
          ) : (
            <>
              {suggestions.map((item) => (
                <div
                  className={
                    companyDetails[0]?.unnlock_id === item.value
                      ? "suggestion-selected"
                      : "suggestion"
                  }
                  onClick={() => {
                    if (companyLoader) {
                      unnlockNotification("Please wait!", "info");
                    } else {
                      handleSelect(item);
                    }
                  }}
                >
                  {item.text}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmYourCompanyCard;
