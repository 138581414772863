/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import history from "../../routes/History";
import "./Login.scss";
import SocialRegistration from "../NewRegistration/socialLogin";
import CommonInput from "../ICDProvider/commonInput";
import ForgotPassModal from "./forgotPassModal";

const DisplayingErrorMessagesSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
  password: Yup.string()
    .required("No password provided.")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
});

const NewLogin = ({
  setUploadResume,
  showUploadResume,
  setRegistrationData,
  handleClick,
}) => {
  const dispatch = useDispatch();
  const { loader, userData } = useSelector((state) => state.login);
  const registerState = useSelector((state) => state.register);

  const [isModalEmailVisible, setIsModalEmailVisible] = useState(false);

  const handleLogin = (data) => {
    dispatch(LoginActions.loginRequest(data));
    setRegistrationData({
      first_name: "",
      last_name: "",
      ...data,
    });
  };

  useEffect(() => {
    if (userData && userData.primary_resume) {
      if (!showUploadResume && userData.onboarding_complete)
        history.push("/dashboard");
      else history.push("/onBoarding");
    } else if (userData && !userData.primary_resume) {
      setUploadResume(true);
      localStorage.clear();
    }
  }, [userData]);

  // useEffect(() => {
  //   if (registerState.uploadResumeSuccess) {
  //     if (localStorage.getItem("unnlockUserToken"))
  //       dispatch(LoginActions.getUserDataRequest());
  //     // history.push("/dashboard");
  //   }
  // }, [registerState]);

  return (
    <div className="login-container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          // padding: "0% 5% 0% 25%",
        }}
      >
        <div className="login-section">
          <div
            style={{
              fontSize: "20px",
              fontWeight: 500,
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            Existing User
          </div>
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: 500,
                marginBottom: "10px",
                textAlign: "center",
              }}
            >
              Log in using social networks
            </p>
            <SocialRegistration fromLogin={true} />
          </div>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={DisplayingErrorMessagesSchema}
            onSubmit={(values) => {
              // console.log(values, "export default");
              handleLogin(values);
            }}
          >
            {({ errors, touched }) => (
              <Form>
                <CommonInput formField name="email" placeholder="Email" />
                <div
                  className="error_fields"
                  style={{
                    color: touched.email && errors.email ? "" : "transparent",
                  }}
                >
                  {errors?.email}
                </div>
                <CommonInput
                  formField
                  name="password"
                  placeholder="Password"
                  type="password"
                />
                <div
                  className="error_fields"
                  style={{
                    color:
                      touched.password && errors.password ? "" : "transparent",
                  }}
                >
                  {errors?.password}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <button type="submit" className="login_btn">
                    Log in
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      setIsModalEmailVisible(!isModalEmailVisible);
                      // history.push("/forgotPassword");
                    }}
                    className="forgotpass_btn"
                  >
                    Forgot Password?
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="signUpBtn">
            <button onClick={handleClick}>New User Sign up</button>
          </div>
        </div>
      </div>
      <ForgotPassModal visible={isModalEmailVisible} />
    </div>
  );
};

export default NewLogin;
