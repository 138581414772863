import { put, takeLatest, call, select } from "redux-saga/effects";
import * as ACTIONS from "./toolsActions";
import * as TYPES from "./toolsTypes";
import * as COMPANYACTIONS from "../componies/action";
import { getSubsCountRequest } from "../newLogin/newLoginAction";
import Network from "../../network";
import history from "../../routes/History";
import unnlockNotification from "../../components/notification";
import { isEmpty } from "lodash";

const { API, Config } = Network;
/* eslint-disable */

function* postMatchJob(action) {
  yield put(ACTIONS.setToolsLoader(true));
  try {
    const params = action.payload;
    const { formData, primaryResumeId } = params;

    const result = yield call(
      API.post,
      Config.toolsDetails.postMatchJob(primaryResumeId),
      formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.postMatchJobSuccess(result));
    yield put(ACTIONS.setToolsLoader(false));
  } catch (error) {
    yield put(ACTIONS.setToolsLoader(false));
    yield put(ACTIONS.postMatchJobFailure(error));
  }
}

function* handleOpenJobs(action) {
  yield put(ACTIONS.setToolsLoader(true));
  try {
    const userData = yield select((state) => state.login.userData);
    const formData = action.payload;
    const result = yield call(
      API.post,
      Config.toolsDetails.getOpenJobs,
      formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("subscription Detailssss:::", result);
    yield put(ACTIONS.getOpenJobsSuccess(result));
    yield put(ACTIONS.setToolsLoader(false));
    if (Object.keys(result).length > 2) {
      history.push("/OpenJobComapnies");
    } else {
      unnlockNotification(result?.message, "warning", 0, 10);
    }
    if (userData?.tier_id === 0)
      yield put(
        getSubsCountRequest({
          key: "open_jobs_count",
          label: "open job search",
        })
      );
  } catch (error) {
    yield put(ACTIONS.setToolsLoader(false));
    yield put(ACTIONS.getOpenJobsFailure(error));
  }
}

function* postCompUrls(action) {
  yield put(ACTIONS.setToolsLoader(true));
  try {
    const params = action.payload;
    const { formData, primaryResumeId } = params;

    const result = yield call(
      API.post,
      Config.toolsDetails.postCompURLs(primaryResumeId),
      formData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("company urls Detailssss:::", result);
    yield put(ACTIONS.postCompanyURLS(result));
    yield put(ACTIONS.setToolsLoader(false));
  } catch (error) {
    yield put(ACTIONS.setToolsLoader(false));
    yield put(ACTIONS.postCompanyURLSFailure(error));
  }
}

function* getMatchingSkills(action) {
  yield put(ACTIONS.setToolsLoader(true));
  try {
    const params = action.payload;
    const { title } = params;

    const result = yield call(API.get, Config.toolsDetails.getSkills(title), {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });

    // console.log("skills Detailssss:::", result);
    yield put(ACTIONS.getMatchingSkillsSuccess(result));
    yield put(ACTIONS.setToolsLoader(false));
  } catch (error) {
    yield put(ACTIONS.setToolsLoader(false));
    yield put(ACTIONS.getMatchingSkillsFailure(error));
  }
}

function* getSalComp(action) {
  // yield put(ACTIONS.setToolsLoader(true));
  yield put(COMPANYACTIONS.companiesLoader(true));
  try {
    const params = action.payload;
    const { experience, designation } = params;

    const result = yield call(
      API.get,
      Config.toolsDetails.getComponsation(experience, designation),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("Salary Comp Detailssss:::", result);
    yield put(ACTIONS.getSalaryComponsationSuccess(result));
    // yield put(ACTIONS.setToolsLoader(false));
    yield put(COMPANYACTIONS.companiesLoader(false));
  } catch (error) {
    // yield put(ACTIONS.setToolsLoader(false));
    yield put(COMPANYACTIONS.companiesLoader(false));
    yield put(ACTIONS.getSalaryComponsationFailure(error));
  }
}

function* getSkillsFromDesignation(action) {
  yield put(ACTIONS.setToolsLoader(true));
  try {
    const params = action.payload;
    const { title } = params;

    const result = yield call(
      API.get,
      Config.toolsDetails.getSkillsFromDesig(title),
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    // console.log("Salary Comp Detailssss:::", result);
    yield put(ACTIONS.getSkillsfromDesigSuccess(result));
    yield put(ACTIONS.setToolsLoader(false));
  } catch (error) {
    yield put(ACTIONS.setToolsLoader(false));
    yield put(ACTIONS.getSkillsfromDesigFailure(error));
  }
}

function* handleChatBotAnswers(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.post,
      `${Config.toolsDetails.getChatBotAnswers(params?.useOld)}${
        params?.regenerate ? "?regen=yes" : ""
      }`,
      {
        text: params.text,
        resume_id: params.resume_id,
        prompt_id: params.prompt_id,
      },
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getChatBotAnswersSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getChatBotAnswersFailure(error));
  }
}

function* handlegetAiSearchedJobs(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.post,
      Config.toolsDetails.getAiSearchedJobs,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getAiSearchedJobsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getAiSearchedJobsFailure(error));
  }
}

function* handleOpenJobChatBotAnswers(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.post,
      Config.toolsDetails.getOpenJobChatBotAnswers,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.geOpenJobChatBotAnswersSuccess(result));
  } catch (error) {
    yield put(ACTIONS.geOpenJobChatBotAnswersFailure(error));
  }
}

function* handlegetChatBotQuestion(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.get,
      Config.toolsDetails.getChatBotQuestions,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getChatBotQuestionsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getChatBotQuestionsFailure(error));
  }
}

function* handleGetJobMatchFitRequest(action) {
  try {
    const params = action.payload;
    yield put(ACTIONS.getJobMatchFitSuccess(null));
    const result = yield call(
      API.post,
      Config.toolsDetails.getJobMatchFit,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getJobMatchFitSuccess(result.response));
  } catch (error) {
    yield put(ACTIONS.getJobMatchFitFailure(error));
  }
}

function* handleGetJobMatchSuggestionRequest(action) {
  try {
    const params = action.payload;
    yield put(ACTIONS.getJobMatchSuggestionSuccess(null));
    const result = yield call(
      API.post,
      Config.toolsDetails.getJobMatchFit,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getJobMatchSuggestionSuccess(result.response));
  } catch (error) {
    yield put(ACTIONS.getJobMatchSuggestionFailure(error));
  }
}

function* handleGetCoverLetterRequest(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.post,
      Config.toolsDetails.getCoverLetter,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getCoverLetterSuccess(result.response));
  } catch (error) {
    yield put(ACTIONS.getCoverLetterFailure(error));
  }
}

export function* postMatchJobDetails() {
  yield takeLatest(TYPES.POST_MATCHJOB_REQUEST, postMatchJob);
}
export function* getSalaryComp() {
  yield takeLatest(TYPES.GET_SAL_COMP_REQUEST, getSalComp);
}
export function* getSkillsfromDesig() {
  yield takeLatest(TYPES.GET_SKILL_DESIG_REQUEST, getSkillsFromDesignation);
}
export function* postCompURLS() {
  yield takeLatest(TYPES.POSTCOMP_URLS_REQUEST, postCompUrls);
}
export function* getMatchSkills() {
  yield takeLatest(TYPES.GETMATCHING_SKILLS_REQUEST, getMatchingSkills);
}
export function* getOpenJobsRequest() {
  yield takeLatest(TYPES.GET_OPEN_JOBS_REQUEST, handleOpenJobs);
}
export function* getChatBotAnswersRequest() {
  yield takeLatest(TYPES.GET_CHATBOT_ANSWER_REQUEST, handleChatBotAnswers);
}
export function* getAiSearchedJobsRequest() {
  yield takeLatest(TYPES.GET_AI_SEARCHED_JOB_REQUEST, handlegetAiSearchedJobs);
}
export function* getChatBotQuestionRequest() {
  yield takeLatest(
    TYPES.GET_CHAT_BOT_QUESTIOS_REQUEST,
    handlegetChatBotQuestion
  );
}
export function* getOpenJobChatBotAnswersRequest() {
  yield takeLatest(
    TYPES.GET_OPEN_JOB_CHATBOT_ANSWER_REQUEST,
    handleOpenJobChatBotAnswers
  );
}
export function* getJobMatchFitRequest() {
  yield takeLatest(TYPES.GET_JOBMATCH_FIT_REQUEST, handleGetJobMatchFitRequest);
}
export function* getJobMatchSuggestionRequest() {
  yield takeLatest(
    TYPES.GET_JOBMATCH_SUGGESTION_REQUEST,
    handleGetJobMatchSuggestionRequest
  );
}
export function* getCoverLetterRequest() {
  yield takeLatest(TYPES.GET_COVER_LETTER_REQUEST, handleGetCoverLetterRequest);
}
