/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Heart from "../../assets/images/componies/heart.svg";
import Heartfilled from "../../assets/images/componies/heartFilled.svg";
import ArrowLeft from "../../assets/images/componies/ArrowLeft.svg";
import history from "../../routes/History";
import "./TopBar.scss";
import { getResumeVaultsRequest } from "../../store/resumeOptimizer/resumeOptimizerActions";

const ComponiesTopBar = () => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const { openJobs: matchedjobsList, selectedResumeId: currentResume } =
    useSelector((state) => state.tools);
  const userData = useSelector((state) => state.login.userData);
  const [resumeName, SetResumeName] = useState("");
  const { resumeVaults } = useSelector((state) => state.resumeOptimizer);
  const activeTab = true;

  useEffect(() => {
    if (currentResume) {
      SetResumeName(
        resumeVaults.filter((item) => item.resume_id === currentResume)[0]
          ?.file_name
      );
    }
    if (!resumeVaults?.length) {
      dispatch(getResumeVaultsRequest());
    }
  }, [currentResume, resumeVaults]);

  return (
    <div className="topbar_container">
      <div className="topbar_container_inner">
        <div className="companies_matched">
          <div
            onClick={() => {
              if (window?.location?.pathname === "/openJobsDetails") {
                history.push("/OpenJobComapnies");
              } else {
                history.push("/OpenJobs");
              }
            }}
          >
            <img
              src={ArrowLeft}
              alt="arrow"
              style={{
                marginBottom: "4px",
                width: "20px",
                marginRight: "8px",
                cursor: "pointer",
              }}
            />
            <span>
              {" "}
              {window?.location?.pathname === "/openJobsDetails"
                ? ""
                : "Search |"}{" "}
              Open Jobs |
            </span>
          </div>
          <div />
          <div style={{ paddingLeft: "5px" }}> Current Resume |</div>
          <div style={{ paddingLeft: "5px" }}>{resumeName}</div>
          {/* {loginState?.subsCount && loginState?.userData?.tier_id === 0 ? (
            <div
              style={{
                fontSize: "16px",
                padding: "0px 10px",
                marginLeft: "10px",
                borderRadius: "4px",
                background: "rgb(245 124 66 / 55%)",
                color: "white",
                fontWeight: 500,
              }}
            >{`${loginState?.subsCount?.open_jobs_count} free trials left.`}</div>
          ) : null} */}
        </div>

        <div className="results_favorites">
          <div
            style={{
              color: activeTab === "results" ? "#4389FA" : "",
              fontWeight: activeTab === "results" ? 600 : 500,
            }}
            onClick={() => handleTabChange("results")}
            className="results"
          >
            Results ( {matchedjobsList?.jobs_list?.length || "0"} )
          </div>
          <div
            style={{
              color: activeTab === "favrates" ? "#4389FA" : "",
              fontWeight: activeTab === "favrates" ? 600 : 500,
            }}
            className={"favrates"}
            onClick={() => handleTabChange("favrates")}
          >
            <img
              src={activeTab === "favrates" ? Heartfilled : Heart}
              alt="favorite"
              className="favIcon"
            />
            <p style={{ margin: 0 }}>
              {" "}
              Favorites ( {userData?.favourite_jobs?.length || 0} )
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComponiesTopBar;
