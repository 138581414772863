import React from "react";
import "./index.scss";

function index() {
  const currentPath = window.location.pathname || "";
  return (
    <div className="onboardingHeader">
      <div className="step">
        <div
          className="numbercircle"
          style={{
            background:
              currentPath === "/newRegistration"
                ? "#4389fa"
                : currentPath === "/onBoarding" || currentPath === "/lastStep"
                  ? "#6ebf67"
                  : "#606060",
          }}
        >
          <p className="numbertext">1</p>
        </div>
        <p
          className="addResumeText"
          style={{
            color:
              currentPath === "/newRegistration"
                ? "#4389fa"
                : currentPath === "/onBoarding" || currentPath === "/lastStep"
                  ? "#6ebf67"
                  : "#606060",
          }}
        >
          Add your resume
        </p>
      </div>
      <span className="line"></span>
      <div className="step">
        <div
          className="numbercircle"
          style={{
            background:
              currentPath === "/onBoarding"
                ? "#4389fa"
                : currentPath === "/lastStep"
                  ? "#6ebf67"
                  : "#606060",
          }}
        >
          <p className="numbertext">2</p>
        </div>
        <p
          className="addResumeText"
          style={{
            color:
              currentPath === "/onBoarding"
                ? "#4389fa"
                : currentPath === "/lastStep"
                  ? "#6ebf67"
                  : "#606060",
          }}
        >
          Confirm Employer
        </p>
      </div>
      <span className="line"></span>
      <div className="step">
        <div
          className="numbercircle"
          style={{
            background: currentPath === "/lastStep" ? "#4389fa" : "#606060",
          }}
        >
          <p className="numbertext">3</p>
        </div>
        <p
          className="addResumeText"
          style={{
            color: currentPath === "/lastStep" ? "#4389fa" : "#606060",
          }}
        >
          Select Your Role
        </p>
      </div>
    </div>
  );
}

export default index;
