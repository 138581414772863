/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./CompanyDetails.scss";

const checkvalidArray = (data) => {
  if (Array.isArray(data) && data.length) {
    return true;
  } else return false;
};

const HardAndSoftSkills = ({
  companyDeatils,
  title,
  handleSocsClick,
  socsArrays,
}) => {
  const dashboardSkills = useSelector(
    (state) => state.dashboard.skillsForJobMatch
  );
  // const skillsForAtsView = useSelector(
  //   (state) => state.dashboard.skillsForAtsView
  // );
  const socLoader = useSelector((state) => state.componies.socLoader);
  // const hardSkills =
  //   skillsForAtsView?.filter((item) => item?.id === "ST1") || [];
  // const softSkills =
  //   skillsForAtsView?.filter((item) => item?.id === "ST2") || [];
  // const yourSkills = title === "Hard Skills" ? hardSkills : softSkills;

  const { user_socs, loader } = useSelector((state) => state.login.userData);

  useEffect(() => {
    handleSocsClick(user_socs[0]?.soc_id);
  }, []);

  let totalCompanySkills = [];
  let matchedSkills = [];

  if (
    checkvalidArray(socsArrays) &&
    checkvalidArray(companyDeatils) &&
    dashboardSkills.jobMatchSkills?.length
  ) {
    totalCompanySkills = [
      ...socsArrays,
      ...companyDeatils.map((item) => item.name),
    ];
    matchedSkills = dashboardSkills.jobMatchSkills.filter((item) =>
      totalCompanySkills.includes(item)
    );
  }

  return (
    <div
      style={{
        borderRadius: "10px",
        // background: "rgba(67, 137, 250, 0.1)",
        //padding: "15px",
        // marginTop: "20px",
        width: "100%",
      }}
    >
      <div>
        <div
          style={{
            background: "white",
            //padding: "20px",
            borderRadius: "8px",
            // maxHeight: "180px",
            overflow: "auto",
            //border: "1px solid rgba(67, 137, 250, 0.4)",
            marginBottom: "20px",
          }}
        >
          <h3 style={{ padding: "20px", borderBottom: "4px solid rgb(233, 241, 255)" }}>
            Skills at this company matching your resume
          </h3>
          {socLoader ? (
            <h4>Please wait..!</h4>
          ) : (
            <div style={{ padding: "20px" }}>
              {checkvalidArray(matchedSkills)
                ? matchedSkills.map((skillname) => (
                  <span style={{ margin: "8px 0px" }}>
                    <span
                      style={{
                        backgroundColor: "rgb(233, 241, 255)",
                        fontWeight: 500,
                        fontSize: "15px",
                      }}
                    >
                      {skillname}
                    </span>
                    <span
                      style={{
                        color: "rgba(67, 137, 250, 0.4)",
                        margin: "0px 8px",
                      }}
                    >
                      {" "}
                      |{" "}
                    </span>
                  </span>
                ))
                : null}
            </div>
          )}
          <p style={{ margin: "20px", paddingTop: "20px", borderTop: "2px solid #d5e0f3" }}>
            These are your resume skills the company finds valuable. Company seem a good fit? Add to your favorites.
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div
            style={{
              background: "white",
              //padding: "20px",
              borderRadius: "8px",
              // maxHeight: "180px",
              overflow: "auto",
              width: "50%",
              //border: "1px solid rgba(67, 137, 250, 0.4)",
            }}
          >
            <h3 style={{ padding: "20px", borderBottom: "4px solid rgb(233, 241, 255)" }}>
              {user_socs[0]?.name} skills at this company matching your resume
            </h3>
            {socLoader ? (
              <h4>Please wait..!</h4>
            ) : (
              <div style={{ padding: "20px" }}>
                {checkvalidArray(socsArrays)
                  ? socsArrays.map((skillname) => (
                    <span style={{ margin: "8px 0px" }}>
                      {dashboardSkills.jobMatchSkills?.includes(skillname) ? (
                        <>
                          <span
                            style={{
                              backgroundColor: "rgb(233, 241, 255)",
                              fontWeight: 500,
                              fontSize: "15px",
                            }}
                          >
                            {skillname}
                          </span>
                          <span
                            style={{
                              color: "rgba(67, 137, 250, 0.4)",
                              margin: "0px 8px",
                            }}
                          >
                            {" "}
                            |{" "}
                          </span>
                        </>
                      ) : (
                        <>
                          <span> {skillname}</span>
                          <span
                            style={{
                              color: "rgba(67, 137, 250, 0.4)",
                              margin: "0px 8px",
                            }}
                          >
                            {" "}
                            |{" "}
                          </span>
                        </>
                      )}
                    </span>
                  ))
                  : null}
              </div>
            )}
            <p style={{ margin: "20px", paddingTop: "20px", borderTop: "2px solid #d5e0f3" }}>
              This role includes these skills. Highlighted skills are already in your resume!
            </p>
          </div>

          <div
            style={{
              background: "white",
              //padding: "20px",
              borderRadius: "8px",
              // maxHeight: "180px",
              overflow: "auto",
              width: "50%",
              //border: "1px solid rgba(67, 137, 250, 0.4)",
            }}
          >
            <h3 style={{ padding: "20px", borderBottom: "4px solid rgb(233, 241, 255)" }}>
              All skills at this company
            </h3>
            <div style={{ padding: "20px" }}>
              {checkvalidArray(companyDeatils)
                ? companyDeatils.map((skill) => (
                  <span style={{ margin: "8px 0px" }}>
                    {dashboardSkills.jobMatchSkills?.includes(skill.name) ? (
                      <>
                        <span
                          style={{
                            backgroundColor: "rgb(233, 241, 255)",
                            fontWeight: 500,
                            fontSize: "15px",
                          }}
                        >
                          {skill.name}
                        </span>
                        <span
                          style={{
                            color: "rgba(67, 137, 250, 0.4)",
                            margin: "0px 8px",
                          }}
                        >
                          {" "}
                          |{" "}
                        </span>
                      </>
                    ) : (
                      <>
                        <span> {skill.name}</span>
                        <span
                          style={{
                            color: "rgba(67, 137, 250, 0.4)",
                            margin: "0px 8px",
                          }}
                        >
                          {" "}
                          |{" "}
                        </span>
                      </>
                    )}
                  </span>
                ))
                : null}
            </div>
            <p style={{ margin: "20px", paddingTop: "20px", borderTop: "2px solid #d5e0f3" }}>
              Existing employees at the company have these skills.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardAndSoftSkills;
