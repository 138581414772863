/* eslint-disable */
import { put, takeLatest, call } from "redux-saga/effects";
import { message } from "antd";
import jwt_decode from "jwt-decode";
import * as ACTIONS from "./newLoginAction";
import * as TYPES from "./newLoginTypes";
import Network from "../../network";
import history from "../../routes/History";
import { getprofileSuccess } from "../profile/profileActions";
import unnlockNotification from "../../components/notification";
import { saveUserSocsRequest } from "../newRegister/registerActions";

const { API, Config } = Network;

function* sagaLogin(action) {
  try {
    const loginUrl = Config.login.userLogin;
    const params = action.payload;
    const { email, password } = params;
    const result = yield call(API.post, loginUrl, { email, password });
    const { msg, status, id_token, refresh_token } = result;
    if (id_token && status !== "fail") {
      const userData = { ...result };
      localStorage.setItem("unnlockUserToken", id_token);
      localStorage.setItem("unnlockRefreshToken", refresh_token);
      yield put(ACTIONS.getUserDataRequest());
      yield put(ACTIONS.loginSuccess({ userData, loginType: "" }));
    } else {
      yield put(ACTIONS.loginFailure(msg));
      unnlockNotification(msg, "error", 52);
    }
  } catch (err) {
    yield put(ACTIONS.loginFailure(err));
  }
}

function* sagaGetUser(action) {
  try {
    const result = yield call(API.get, Config.registration.getUserInformation, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    if (result && result.user_id)
      localStorage.setItem("unnlockUserID", result.user_id);
    yield put(ACTIONS.getUserDataSuccess(result));
    yield put(getprofileSuccess(result));
    if (action?.payload?.ShowWarning && result.unnbot_processing === false) {
      unnlockNotification(
        "We are still processing please try after some time!",
        "warning"
      );
    }
  } catch (error) {
    yield put(ACTIONS.getUserDataFailure(error));
    // yield put(ACTIONS.checkTokanExpiry());
  }
}

function* emailVerifyCall(action) {
  try {
    const params = action.payload;
    const result = yield call(API.post, Config.registration.emailVerify, params,  {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    if (result){
      unnlockNotification(
        "Email Sent Successfully!",
        "success"
      );
    }
  } catch (error) {
    yield put(ACTIONS.getUserDataFailure(error));
    // yield put(ACTIONS.checkTokanExpiry());
  }
}

function* handleForgotPass(action) {
  try {
    const params = action.payload;
    const { email } = params;

    const result = yield call(
      API.get,
      Config.userRestoreCred.newForgotPassUrl(email)
    );

    result.email = email;

    unnlockNotification(
      "Please Check your email for confirmation code, which is valid for 10 minutes only",
      "success",
      52
    );
    // history.push({
    //   pathname: "/resetpassword",
    //   state: { forgotEmail: email },
    // });
    yield put(ACTIONS.forgotPassReceive(result));
  } catch (error) {
    unnlockNotification("Given Email Not found", "error", 52);
    yield put(ACTIONS.forgotPassFailure(error));
  }
}

function* handleResetPass(action) {
  try {
    const params = action.payload;
    const { email, code, new_password } = params;

    const result = yield call(
      API.post,
      Config.userRestoreCred.newResetPassUrl,
      {
        email,
        code,
        new_password,
      }
    );
    const { status } = result;
    if (status === "ERROR") {
      unnlockNotification(result.message, "error", 52);
      yield put(ACTIONS.resetPasswordFailure(result));
    } else {
      unnlockNotification("Password Reset Is Done!", "success", 52);
      yield put(ACTIONS.resetPasswordReceive(result));
    }
  } catch (error) {
    unnlockNotification("Password Reset Is Failed!", "error", 52);
    yield put(ACTIONS.resetPasswordFailure(error));
  }
}

function* handleRefreshLogin(action) {
  try {
    // const params = action.payload;
    const result = yield call(API.post, Config.refreshLogin.refreshLoginUrl, {
      user_id: localStorage.getItem("unnlockUserID"),
      refresh_token: localStorage.getItem("unnlockRefreshToken"),
    });
    const { msg, status, id_token } = result;
    if (id_token && status !== "fail") {
      const userData = { ...result };
      localStorage.setItem("unnlockUserToken", id_token);
      // localStorage.setItem("unnlockRefreshToken", refresh_token);
      yield put(ACTIONS.getUserDataRequest());
      yield put(ACTIONS.loginSuccess({ userData, loginType: "" }));
      unnlockNotification("Logging you in back please wait.", "success", 52);
    } else {
      yield put(ACTIONS.loginFailure(msg));
      unnlockNotification(msg, "error", 52);
    }
    // yield put(ACTIONS.refreshLoginSuccess(result));
  } catch (error) {
    yield put(ACTIONS.refreshLoginFailure(error));
    // window.localStorage.clear();
    // history.push("/");
  }
}

function* handleResetCheckTokanExpiry() {
  try {
    const decoded = jwt_decode(localStorage.getItem("unnlockUserToken"));
    const date = new Date(0);
    const tokExpiryDate = date.setUTCSeconds(decoded.exp);
    if (tokExpiryDate.valueOf() < new Date().valueOf()) {
      console.log("called refresh login function");
      yield put(
        ACTIONS.refreshLoginRequest({
          user_id: "",
        })
      );
    }
  } catch (error) {
    console.log(error);
  }
}

function* getMatchingIndusties(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.post,
      Config.registration.getUserIndustries,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.getUserIndustriesSuccess(result));
    yield put(saveUserSocsRequest({ user_industries: result }));
  } catch (error) {
    yield put(ACTIONS.getUserIndustriesFailure(error));
  }
}

function* handleSubsCount(action) {
  try {
    // const params = action.payload;
    const result = yield call(API.get, Config.registration.getSubsCount, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getSubsCountSuccess(result));
    // unnlockNotification(
    //   `You left with ${result[params.key]} ${params?.label} free trials`,
    //   "warning",
    //   52,
    //   10
    // );
  } catch (error) {
    yield put(ACTIONS.getSubsCountFailure(error));
  }
}

function* handleApplyCoupon(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.get,
      Config.registration.applyCoupon + `?promo=${params}`,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );

    if (result.message) {
      unnlockNotification(result.message, "warning", 52, 10);
      yield put(ACTIONS.applyCouponFailure(error));
    } else {
      yield put(ACTIONS.getUserDataRequest());
      yield put(ACTIONS.applyCouponSuccess(result));
      unnlockNotification(`Coupon applied successfully.`, "success", 52, 10);
      // history.goBack();
    }
  } catch (error) {
    yield put(ACTIONS.applyCouponFailure(error));
  }
}

export function* requestResetPass() {
  yield takeLatest(TYPES.RESET_PASSWORD_REQUEST, handleResetPass);
}
export function* requestForgotPass() {
  yield takeLatest(TYPES.FORGOT_PASSWORD_REQUEST, handleForgotPass);
}
export function* requestUserLogin() {
  yield takeLatest(TYPES.LOGIN_REQUEST, sagaLogin);
}
export function* requestUserData() {
  yield takeLatest(TYPES.GET_USER_DETAILS_REQUEST, sagaGetUser);
}
export function* requestEmailVerification() {
  yield takeLatest(TYPES.EMAIL_VERIFICATION_REQUEST, emailVerifyCall);
}
export function* onRefreshLogin() {
  yield takeLatest(TYPES.REFRESH_LOGIN_REQUEST, handleRefreshLogin);
}
export function* requestCheckTokanExpiry() {
  yield takeLatest(TYPES.CHECK_TOKAN_EXPIRY, handleResetCheckTokanExpiry);
}
export function* requestIndustries() {
  yield takeLatest(TYPES.GET_USER_INDUSTRIES_REQUEST, getMatchingIndusties);
}
export function* requestSubsCount() {
  yield takeLatest(TYPES.GET_SUBSCRIPTION_COUNT_REQUEST, handleSubsCount);
}
export function* requestApplyCoupon() {
  yield takeLatest(TYPES.APPLY_COUPON_REQUEST, handleApplyCoupon);
}
