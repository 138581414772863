/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin, Checkbox, message } from "antd";
import * as RegisterActions from "../../store/newRegister/registerActions";
import * as LoginActions from "../../store/newLogin/newLoginAction";
import history from "../../routes/History";
import UploadResume from "../NewRegistration/uploadResume";
import titleImage from "../../assets/images/UnnlockNew.svg";
import OnboardingHeader from "../../components/OnboardingHeader";
import "./style.scss";
import UnnlockLayout from "../layout";


const NewRegistrationUploadResume = () => {
  const dispatch = useDispatch();
  const {
    loader,
    dataAfterRegister,
    userAlreadyExists,
    uploadResumeSuccess,
    dataAfterResumeParse,
    uploadResumeLoader,
    preUpladData,
    error,
  } = useSelector((state) => state.register);
  const loginState = useSelector((state) => state.login);
  const [showUploadResume, setUploadResume] = useState(false);

  const parseResume = (data, filename) => {
    const postData = {
      resData: data,
      userId: loginState.userData
        ? loginState.userData.user_id
        : dataAfterRegister.user_id,
      file_name: filename,
    };
    dispatch(RegisterActions.uploadResumeRequest(postData));
  };

  useEffect(() => {
    if (dataAfterRegister && !userAlreadyExists) {
      localStorage.clear();
      if (preUpladData !== "") {
        parseResume(preUpladData.resData, preUpladData.file_name);
      } else {
        setUploadResume(true);
      }
    }
  }, [dataAfterRegister]);

  useEffect(() => {
    if (uploadResumeSuccess && dataAfterResumeParse) {
      dispatch(
        LoginActions.loginRequest({
          email: registrationData.email,
          password: registrationData.password,
        })
      );
      if (preUpladData !== "") {
        setUploadResume(true);
      }
    }
  }, [uploadResumeSuccess]);

  useEffect(() => {
    if (preUpladData !== "") {
      setisRegistrationScreen(true);
    }
  }, [preUpladData]);

  useEffect(() => {
    if (localStorage) {
      const userTokan = localStorage.getItem("unnlockUserToken");
      if (userTokan) {
        history.push("/dashboard");
      }
    }
  }, []);

  const preUploadLoader = preUpladData !== "" ? uploadResumeLoader : false;

  return (
    <UnnlockLayout
      dontDisplayHeader={true}
      background="white"
      optionalHeader={
        <>
          <div className="loginPage_header">
            <div className="loginPage_header_inner">
              <img src={titleImage} alt="titleImage" className="unnlockIcon" onClick={() => {
                history.push("/");
              }} />
            </div>
          </div>
          {<OnboardingHeader />}
        </>
      }
    >
      <div className="register-container">
        <Spin
          size="large"
          spinning={(!showUploadResume && loader) || preUploadLoader}
          style={{
            fontSize: "24px",
            fontFamily: "sans-serif",
            fontWeight: "bolder",
            color: "purple",
            height: "100%",
          }}
        >
          <UploadResume parseResume={parseResume} />
        </Spin>
      </div>
    </UnnlockLayout>
  );
};

export default NewRegistrationUploadResume;
