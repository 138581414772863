import React, { useEffect, useState, useRef } from "react";
import { Modal, Drawer, Button, Slider, Switch, Form, Skeleton } from "antd";
import download from "../../assets/images/resumeVault/download.svg";
import "./index.scss";
import Preview from "./preview";
import CommonInput from "../ICDProvider/commonInput";
import unnlockNotification from "../../components/notification";

const PreviewModal = ({ visible, editDownloadFileName }) => {
  // Create a reference to the Preview component
  const downloadLinkRef = useRef(null);

  const [fileName, setFileName] = useState(
    editDownloadFileName ? "" : "Unnlock_Resume"
  );
  const [instanceLoader, setinstanceLoader] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const [sectionSpacing, setSectionSpacing] = useState(1);
  const [lineSpacing, setLineSpacing] = useState(1);
  const [fontSize, setFontSize] = useState(1);
  const [fontWeight, setFontWeight] = useState(400);
  const [enabledSections, setEnabledSections] = useState({
    Subtitle: true,
    Summary: true,
    Contact: true,
    Skills: true,
    Education: true,
    Additional: true,
  });

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const showDrawer = () => {
    setIsDrawerVisible(true);
  };

  const onCloseDrawer = () => {
    setIsDrawerVisible(false);
  };

  const handleSectionToggle = (section, checked) => {
    setEnabledSections({ ...enabledSections, [section]: checked });
  };
  // Function to be executed when the button is clicked
  const handleDownload = () => {
    if (downloadLinkRef && downloadLinkRef.current && fileName) {
      downloadLinkRef.current.click();
    }
    if (!fileName) {
      unnlockNotification("Please Enter File Name", "warning");
    }
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setShouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={null}
      className="preview_model"
      width={editDownloadFileName ? "5.5in" : "8.5in"}
    >
      {/* {editDownloadFileName ? (
        <div style={{ padding: "10px" }}>
          <h3>Please Enter the file.</h3>
          <CommonInput
            label=""
            name="companyName"
            placeholder="Enter file name"
            value={fileName}
            handleChange={(e) => setFileName(e.target.value)}
            style={{
              width: "100%",
              marginTop: "10px",
              marginBottom: "10px",
              border: "none",
              background: "transparent",
            }}
          />
          <Button
            type="primary"
            disabled={instanceLoader}
            onClick={handleDownload}
            style={{ marginRight: 20 }}
          >
            {instanceLoader ? "Loading..!" : "Download"}
          </Button>
          <Button type="link" className="custom_btn" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      ) : (
        <>
          <Button
            type="primary"
            onClick={handleDownload}
            style={{ marginRight: 20 }}
          >
            Download
          </Button>

          <Button type="primary" onClick={showDrawer}>
            Stylize
          </Button>
          <Drawer
            title="Customize Style"
            placement="right"
            closable={true}
            onClose={onCloseDrawer}
            open={isDrawerVisible}
            getContainer={false}
            maskClosable={false}
            mask={false}
            style={{ position: "absolute", width: "50%", left: "8.1in" }}
          >
            <Form layout="vertical">
              <Form.Item label="Section Spacing">
                <Slider
                  defaultValue={sectionSpacing}
                  min={1}
                  max={10}
                  step={1}
                  onAfterChange={setSectionSpacing}
                />
              </Form.Item>
              <Form.Item label="Line Spacing">
                <Slider
                  defaultValue={lineSpacing}
                  min={1}
                  max={2}
                  step={0.1}
                  onAfterChange={setLineSpacing}
                />
              </Form.Item>
              <Form.Item label="Font Size">
                <Slider
                  defaultValue={fontSize}
                  min={1}
                  max={2}
                  step={0.1}
                  onAfterChange={setFontSize}
                />
              </Form.Item>
              <Form.Item label="Font Weight">
                <Slider
                  defaultValue={fontWeight}
                  min={100}
                  max={900}
                  step={100}
                  onAfterChange={setFontWeight}
                />
              </Form.Item>
              <Form.Item label="Sections">
                {[
                  "Subtitle",
                  "Summary",
                  "Contact",
                  "Skills",
                  "Education",
                  "Additional",
                ].map((section) => (
                  <div key={section} style={{ marginBottom: 10 }}>
                    <Switch
                      checked={enabledSections[section]}
                      onChange={(checked) =>
                        handleSectionToggle(section, checked)
                      }
                    />
                    <span style={{ marginLeft: 8 }}>{section}</span>
                  </div>
                ))}
              </Form.Item>
            </Form>
          </Drawer>
        </>
      )} */}
      <div
        style={editDownloadFileName ? { visibility: "hidden", height: 0 } : {}}
      >
        <Preview
          downloadLinkRef={downloadLinkRef}
          key={`${sectionSpacing}-${lineSpacing}-${fontSize}-${fontWeight}`}
          isEdit={false}
          sectionSpacing={sectionSpacing}
          lineSpacing={lineSpacing}
          fontSize={fontSize}
          fontWeight={fontWeight}
          enabledSections={enabledSections}
          fileName={fileName}
          setinstanceLoader={setinstanceLoader}
        />
      </div>
    </Modal>
  );
};

export default PreviewModal;
