/* eslint-disable*/
import React, { useEffect, useState } from "react";
import { Row, Col } from "antd";

import FroalaEditor from "react-froala-wysiwyg";
import NewEditor from "./newEditor";
import { setSuggestionLoader } from "../../../store/resumeOptimizer/resumeOptimizerActions";
import { useDispatch, useSelector } from "react-redux";
import { extractSkillsRequest } from "../../../store/dashBoard/dashboardActions";

const DescriptionEditor = ({
  handleEditorChange,
  id,
  value,
  editInfo,
  level,
  label,
  editIndex,
  currentStep,
  company,
  designation,
}) => {
  const buttons = [
    // "paragraphFormat",
    "bold",
    "italic",
    "underline",
    // "strikeThrough",
    "align",
    "formatOL",
    "formatUL",
    // "insertTable",
    // "insertImage",
    // "insertLink",
    "undo",
    "redo",
    // "quote",
    // "fullscreen",
    // "html",
    // "|",
  ];

  const config = {
    // height: 200,
    toolbarButtons: buttons,
    toolbarButtonsMD: buttons,
    toolbarButtonsSM: buttons,
    toolbarButtonsXS: buttons,
    spellcheck: false,
    placeholderText: "Describe here...",
    key: "nQE2uG3B2D1H1B3B3lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2D1C5G1E1F1H4C10==",
  };
  // const config2 = {
  //   toolbarButtons: buttons,
  //   toolbarButtonsMD: buttons,
  //   toolbarButtonsSM: buttons,
  //   toolbarButtonsXS: buttons,
  //   key: "nQE2uG3B2D1H1B3B3lFa1f1PVWEd2Fa1XHTHh1THMMb1NCg1tA2B2C2D1C5G1E1F1H4C10==",
  //   bgOptions: {
  //     service: {
  //       apiKey: "E8FEF7AE-3F36-4EAF-A451-456D05E6F2A3",
  //       sourcePath:
  //         "//cdn.prowritingaid.com/beyondgrammar/2.0.7546/dist/hayt/bundle.js",
  //     },
  //     grammar: {
  //       languageFilter: ["en-US", "en-GB"],
  //       showContextThesaurus: true,
  //     },
  //   },
  // };

  const [localState, setLocalState] = useState("");
  // const [loader, setLoader] = useState(false);
  const suggestionLoader = useSelector(
    (state) => state.resumeOptimizer.suggestionLoader
  );
  const [startPTagsSuggestions, setstartPTagsSuggestions] = useState(false);
  const [shouldModify, setshouldModify] = useState(true);
  const [liTagSuggestions, setliTagSuggestions] = useState([]);
  const [pTagSuggestions, setpTagSuggestions] = useState([]);
  const [clickedEditor, setClickedEditor] = useState(false);
  const [addContentNotEditable, setAddContentNotEditable] = useState(false);
  const dispatch = useDispatch();

  const getLiTagSuggestionData = async () => {
    dispatch(setSuggestionLoader(true));
    setshouldModify(false);
    let arr = (await removeSuggestionIds(value).split("<li>")) || [];
    console.log(arr);
    let resultArr = [];
    for (let i = 0; i < arr.length; i++) {
      let str = "";
      let resultStr = "";
      if (arr[i].includes("</li>")) {
        try {
          str = await arr[i].split("</li>")[0];
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            localStorage.getItem("unnlockUserToken")
          );

          var raw = JSON.stringify({
            title: designation,
            company: company,
            text: `${str}\nRewrite statement in active voice for my resume`,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            Authorization: localStorage.getItem("unnlockUserToken"), // id_token
            body: raw,
            redirect: "follow",
          };

          let res = await fetch(
            `https://server.unnlock.ai/ai-service/unnlock/rewrite`,
            requestOptions
          );
          let result = await res.json();
          resultStr = result.rephrased_response;
        } catch {
          resultStr = "";
        }
      }
      resultArr = [...resultArr, resultStr];
      setliTagSuggestions(resultArr);
    }
    setstartPTagsSuggestions(false);
    setstartPTagsSuggestions(true);
  };

  useEffect(() => {
    if (liTagSuggestions.length) {
      let arr1 = removeSuggestionIds(value).split("<li>") || [];
      let arr2 = arr1.map((item, i) => {
        let suggestionData = liTagSuggestions[i];
        let str1 = suggestionData
          ? item.replace(
              "</li>",
              `<div contenteditable="false" id="li_div_${i}" style="padding:10px;background:#ECF3FF;border-radius:10px">${suggestionData}<div style="padding-left:88%"><span contenteditable="false" id="li_span_${i}" > </span></div></div></li>`
            )
          : item;
        if (item.includes("</li>")) {
          str1 = `<li id="li_${i}">` + str1;
        }
        return str1;
      });

      const result = arr2.join("") || "";
      setLocalState(result);
    }
  }, [liTagSuggestions]);

  useEffect(() => {
    if (startPTagsSuggestions) getPTagSuggestionData();
  }, [startPTagsSuggestions]);

  const getPTagSuggestionData = async () => {
    let arr = (await value.split("<p>")) || [];
    let resultArr = [];
    for (let i = 0; i < arr.length; i++) {
      let str = "";
      let resultStr = "";
      if (arr[i].includes("</p>")) {
        try {
          str = await arr[i].split("</p>")[0];
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append(
            "Authorization",
            localStorage.getItem("unnlockUserToken")
          );

          var raw = JSON.stringify({
            title: designation,
            company: company,
            text: `${str}\nRewrite statement in active voice for my resume`,
          });

          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow",
          };

          let res = await fetch(
            `https://server.unnlock.ai/ai-service/unnlock/rewrite`,
            requestOptions
          );

          let result = await res.json();

          resultStr = result.rephrased_response;
        } catch {
          resultStr = "";
        }
      }
      resultArr = [...resultArr, resultStr];
      setpTagSuggestions(resultArr);
    }
    dispatch(setSuggestionLoader(false));
    setshouldModify(true);
  };

  useEffect(() => {
    if (pTagSuggestions.length) {
      let arr1 = removePTagSuggestionIds(value).split("<p>") || [];
      let arr2 = arr1.map((item, i) => {
        let suggestionData = pTagSuggestions[i];
        let str1 = suggestionData
          ? item.replace(
              "</p>",
              `<div contenteditable="false" id="p_div_${i}" style="padding:10px;background:#ECF3FF;border-radius:10px">${suggestionData}<div style="padding-left:88%"><span contenteditable="false" id="p_span_${i}" > </span></div></div></p>`
            )
          : item;
        if (item.includes("</p>")) {
          str1 = `<p id="p_${i}">` + str1;
        }
        return str1;
      });
      const result = arr2.join("") || "";
      setLocalState(result);
      setAddContentNotEditable(true);
    }
  }, [pTagSuggestions]);

  useEffect(() => {
    if (!suggestionLoader && localState && addContentNotEditable) {
      const finalResult1 = localState.replace(
        /<li id="li_/g,
        `<li contenteditable="false" id="li_`
      );
      const finalResult2 = finalResult1.replace(
        /<p id="p_/g,
        `<p contenteditable="false" id="p_`
      );
      setLocalState(finalResult2);
      setAddContentNotEditable(false);
    }
  }, [addContentNotEditable, localState]);

  const shouldEdit = () => {
    if (editInfo) {
      return true;
    }
    return false;
  };

  const removePTagSuggestionIds = (data) => {
    let str2 = data.replace(/<p id="p_(\d+(d)*)">/gm, "<p>");
    let str3 = str2.split(">&nbsp;</span></div></div>").map((item) => {
      if (item.includes(`<div contenteditable="false" id="p_div`)) {
        return item.split(`<div contenteditable="false" id="p_div`)[0];
      }
      if (item.includes(`<div contenteditable="false" id="li_div`))
        return item + `>&nbsp;</span></div></div>`;
      else return item;
    });
    return str3.join("").replace(/<p><br><\/p>/gm, "");
  };

  const removeSuggestionIds = (data) => {
    let str1 = data.replace(/<li id="li_(\d+(d)*)">/gm, "<li>");
    let str2 = str1.replace(/<p id="p_(\d+(d)*)">/gm, "<p>");
    let str3 = str2.split(">&nbsp;</span></div></div>").map((item) => {
      if (item.includes("<div contenteditable")) {
        return item.split("<div contenteditable")[0];
      }
      return item;
    });
    return str3.join("").replace(/<p><br><\/p>/gm, "");
  };

  const handleClick = () => {
    // setshouldModify(false);
    getLiTagSuggestionData();
    setClickedEditor(false);
    document
      ?.getElementsByClassName("fr-wrapper")[0]
      ?.addEventListener("click", function () {
        setClickedEditor(true);
      });
  };

  useEffect(() => {
    if (clickedEditor) {
      document.getElementsByTagName("input")[4].focus();
      document.getElementsByTagName("input")[4].blur();
      // setLocalState(removeSuggestionIds(value));
    }
  }, [clickedEditor]);

  useEffect(() => {
    localStorage.setItem("liSuggestions", "");
    localStorage.setItem("pSuggestions", "");
    setLocalState(removeSuggestionIds(value));
    handleEditorChange(value, id, true);
    dispatch(setSuggestionLoader(false));
  }, [editIndex]);

  useEffect(() => {
    if (value && currentStep == 3) {
      dispatch(extractSkillsRequest(removeSuggestionIds(value)));
    }
    if (value && currentStep == 2) {
      const sel = window.getSelection();
      const innerDiv = document.getElementsByClassName("fr-element")[0];
      if (innerDiv) {
        const innerDivText = innerDiv.firstChild;
        sel.collapse(innerDivText, 0);
        innerDiv.parentNode.focus();
      }
    }
  }, [value, currentStep]);

  return (
    // <Spin size="large" spinning={loader}>
    <div className="job_description_conatiner">
      {label ? (
        <Row justify="space-between">
          <Col>
            <p className="job_description_label">{label}</p>
          </Col>
          <Col>
            <button
              type="button"
              id="compute_description_id"
              onClick={() => {
                handleClick();
              }}
              className="save-button"
              style={{ visibility: "hidden" }}
            >
              compute
            </button>
          </Col>
        </Row>
      ) : null}
      <div className="job_description_inner_conatiner">
        <NewEditor
          value={localState ? localState : value}
          handleEditorChange={(data) => {
            setshouldModify(false);
            setTimeout(() => {
              setshouldModify(true);
            }, 1000);
            setLocalState(data);
            handleEditorChange(data, id, false);
          }}
        />
        <div
          className="job_description"
          style={{ pointerEvents: suggestionLoader ? "none" : "" }}
        >
          <FroalaEditor
            config={config}
            tag="textarea"
            model={localState ? localState : value}
            onModelChange={(e) => {
              handleEditorChange(e, id, shouldModify);
              // setshouldModify(true);
              setLocalState("");
            }}
          />
        </div>
      </div>
    </div>
    // </Spin>
  );
};

export default DescriptionEditor;
