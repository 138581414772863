import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Modal, Radio } from "antd";
import { isEmpty } from "lodash";
import * as RegisterActions from "../../store/newRegister/registerActions";
import history from "../../routes/History";
import unnlockNotification from "../../components/notification";
import "../Profile/model.scss";
import "../RegistrationLastScreen/RegistrationLastScreen.scss";
import WhiteArrowLeft from "../../assets/images/WhiteArrowLeft.svg";

const SocEditModal = ({ visible }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [shouldUpdate, setshouldUpdate] = useState(false);
  const dispatch = useDispatch();
  const [socs, setSocs] = useState([]);
  const [NMsocs, setNMSocs] = useState([]);
  const [selectedTab, setselectedTab] = useState("");
  const [selectedSocs, setSelectedSocs] = useState([]);
  const socsState = useSelector((state) => state.register.user_socs);
  const loginState = useSelector((state) => state.login);
  const [value, setValue] = useState(0);

  const onChange = (e) => {
    setSelectedSocs([]);
    setValue(e.target.value);
    setselectedTab("");
  };

  const handleClick = (item) => {
    if (selectedSocs.includes(item.soc_id)) {
      const index = selectedSocs.indexOf(item.soc_id);
      if (index > -1) {
        const arr = [...selectedSocs];
        arr.splice(index, 1);
        setSelectedSocs(arr);
      }
    } else {
      if (selectedSocs.length === 1) {
        setSelectedSocs([item.soc_id]);
      } else {
        setSelectedSocs([...selectedSocs, item.soc_id]);
      }
    }
  };

  useEffect(() => {
    if (selectedTab !== "") {
      const selectedArr =
        socsState["Non-Management-details"][selectedTab.i][selectedTab.name];
      setNMSocs(selectedArr);
      if (selectedArr.length > 1) {
        setSocs([selectedTab]);
      } else {
        handleClick(selectedArr[0]);
      }
    }
  }, [selectedTab]);

  useEffect(() => {
    dispatch(RegisterActions.getAllSocsRequest());
  }, []);

  useEffect(() => {
    if (socsState) {
      if (value === 1) {
        setSocs(socsState["Management"]);
      } else {
        setSocs(socsState["Non-Management"]);
      }
    }
  }, [socsState, value]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (shouldUpdate) {
      setIsModalVisible(true);
    }
    setshouldUpdate(true);
  }, [visible]);

  return (
    <Modal
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      className="atsViewModal"
    >
      {/* <Skeleton active> */}
      <div className="model_body">
        <div className="content_holder" style={{ width: "100%" }}>
          <div
            className="registrationLastScreenContainer"
            style={{ paddingBottom: "0px", display: "block" }}
          >
            <div
              className="centerCard"
              style={{ width: "100%", marginBottom: "20px" }}
            >
              {/* <p className="cardTitle">One Last Step</p> */}
              <p
                className="cardSubtitle"
                style={{ fontSize: "16px", fontWeight: 600, paddingTop: "32px" }}
              >
                Select your current role
              </p>
              <Radio.Group
                onChange={onChange}
                value={value}
                style={{
                  marginBottom: "20px",
                  marginLeft: "75px",
                  textAlign: "center",
                  display: "flex",
                }}
              >
                <Radio value={1}>Management</Radio>
                <br />
                <Radio value={2}>Individual Contributor</Radio>
              </Radio.Group>
              <div style={{ padding: "16px 75px" }}>
                {value === 2 ? (
                  <div>
                    <List
                      grid={{ gutter: 16, column: 3, xs: 1, sm: 2, md: 3 }}
                      dataSource={socs}
                      renderItem={(item, i) => (
                        <List.Item>
                          <div
                            className={
                              selectedTab.name === item.name
                                ? "selectedSubDiv"
                                : "subdivs"
                            }
                            onClick={() => {
                              setselectedTab({ i, ...item });
                            }}
                          >
                            <p>{item.name}</p>
                          </div>
                        </List.Item>
                      )}
                    />
                    {selectedTab !== "" && NMsocs.length > 1 ? (
                      <List
                        style={{ width: "100%" }}
                        grid={{ gutter: 16, column: 3, xs: 1, sm: 2, md: 3 }}
                        dataSource={NMsocs}
                        renderItem={(item) => (
                          <List.Item>
                            <div
                              className={
                                selectedSocs.includes(item.soc_id)
                                  ? "selectedSubDiv"
                                  : "subdivs"
                              }
                              onClick={() => handleClick(item)}
                            >
                              <p>{item.name}</p>
                            </div>
                          </List.Item>
                        )}
                      />
                    ) : null}
                  </div>
                ) : null}
                {value === 1 ? (
                  <List
                    grid={{ gutter: 16, column: 3, xs: 1, sm: 2, md: 3 }}
                    dataSource={socs}
                    renderItem={(item) => (
                      <List.Item>
                        <div
                          className={
                            selectedSocs.includes(item.soc_id)
                              ? "selectedSubDiv"
                              : "subdivs"
                          }
                          onClick={() => handleClick(item)}
                        >
                          <p>{item.name}</p>
                        </div>
                      </List.Item>
                    )}
                  />
                ) : null}
              </div>
              <div
                style={{
                  cursor: "pointer",
                  padding: "0px 10%",
                  marginTop: "48px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    if (!isEmpty(selectedSocs)) {
                      const obj = {
                        user_socs:
                          value == 1
                            ? socs.filter((item) =>
                              selectedSocs.includes(item.soc_id)
                            )
                            : NMsocs.filter((item) =>
                              selectedSocs.includes(item.soc_id)
                            ),
                      };
                      console.log(obj, selectedSocs);
                      dispatch(RegisterActions.saveUserSocsRequest(obj));
                      handleOk();
                    } else {
                      unnlockNotification(
                        "Please Select Occupation Type!",
                        "warning"
                      );
                    }
                  }}
                >
                  <div
                    // className={!isEmpty(selectedSocs) ? "next" : "next-disabled"}
                    // className="next"
                    style={{
                      backgroundColor: "#4389fa",
                      padding: "10px 20px",
                      borderRadius: "6px",
                      color: "#fff",
                      fontWeight: 500,
                    }}
                  >
                    Update
                  </div>
                </div>
              </div>
            </div>

            {selectedTab !== "" ? (
              <button
                type="button"
                className="nxtButton"
                htmlType="submit"
                onClick={() => {
                  setSocs(socsState["Non-Management"]);
                  setNMSocs([]);
                  setselectedTab("");
                  setSelectedSocs([]);
                }}
              >
                <>
                  <img style={{ transform: "rotate(180deg)" }} src={WhiteArrowLeft} /> Back
                </>

              </button>
            ) : null}
          </div>
        </div>
      </div>
      {/* </Skeleton> */}
    </Modal>
  );
};

export default SocEditModal;
