/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const NewEditor = ({ value, handleEditorChange }) => {
  const suggestionLoader = useSelector(
    (state) => state.resumeOptimizer.suggestionLoader
  );
  const modifyHtmlLi = () => {
    const modifiedHtml = value.match(/li_span_(\d+(d)*)/gm);
    const positions =
      modifiedHtml && Array.isArray(modifiedHtml) && modifiedHtml.length
        ? modifiedHtml.map((id) => {
            return {
              doc: {
                top: document.getElementById(id)?.offsetTop,
                left: document.getElementById(id)?.offsetLeft,
              },
              id: id,
            };
          })
        : [];
    return positions;
  };

  const modifyHtmlP = () => {
    const modifiedHtml = value.match(/p_span_(\d+(d)*)/gm);
    const positions =
      modifiedHtml && Array.isArray(modifiedHtml) && modifiedHtml.length
        ? modifiedHtml.map((id) => {
            return {
              doc: {
                top: document.getElementById(id)?.offsetTop,
                left: document.getElementById(id)?.offsetLeft,
              },
              id: id,
            };
          })
        : [];
    return positions;
  };

  const checkForServerErrorForLi = (item) => {
    if (item.id.split("_")[0] === "li" && !suggestionLoader) {
      const myHTML = document.getElementById(
        item.id.split("_")[0] + "_" + "div_" + `${item.id.split("_")[2]}`
      ).outerHTML;
      const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
      if (
        strippedHtml ===
        "The server had an error while processing your request due to excessive load. Sorry about that! You can retry your request"
      ) {
        return true;
      } else {
        false;
      }
    }
  };

  const checkForServerErrorForP = (item) => {
    if (item.id.split("_")[0] === "p" && !suggestionLoader) {
      const myHTML = document.getElementById(
        item.id.split("_")[0] + "_" + "div_" + `${item.id.split("_")[2]}`
      )?.outerHTML;
      if (myHTML) {
        const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
        if (
          strippedHtml ===
          "The server had an error while processing your request due to excessive load. Sorry about that! You can retry your request"
        ) {
          return true;
        } else {
          false;
        }
      }
    }
  };

  return (
    <div className="list_style">
      {modifyHtmlLi() &&
      Array.isArray(modifyHtmlLi()) &&
      modifyHtmlLi().length ? (
        <div>
          {modifyHtmlLi().map((item) => (
            <div
              style={{
                position: "absolute",
                top: `${item.doc.top + 50}px`,
                left: `${item.doc.left - 60}px`,
                zIndex: 2,
                border: "none",
                // padding: "2px 5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  border: "none",
                  fontWeight: 600,
                  color: "#261C4B",
                  padding: "2px 5px",
                  marginRight: "3px",
                }}
                onClick={() => {
                  let replaceStr = "";
                  if (item.id.split("_")[0] === "li" && !suggestionLoader) {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    replaceStr = `<div contenteditable="false" id="${
                      item.id.split("_")[0] +
                      "_" +
                      "div_" +
                      `${item.id.split("_")[2]}`
                    }".*<span contenteditable="false" id="${
                      item.id
                    }">&nbsp;<\/span><\/div><\/div>`;
                    let str = value.replace(new RegExp(replaceStr), ``);
                    handleEditorChange(
                      str.replace(
                        `<li contenteditable="false" id="${strId}"`,
                        "<li"
                      )
                    );
                  }
                }}
              >
                Ignore?
              </div>
              {checkForServerErrorForLi(item) ? null : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "none",
                    fontWeight: 600,
                    color: "#4389FA",
                    padding: "2px 5px",
                  }}
                  onClick={() => {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    let replaceStr = "";
                    if (item.id.split("_")[0] === "li" && !suggestionLoader) {
                      const myHTML = document.getElementById(
                        item.id.split("_")[0] +
                          "_" +
                          "div_" +
                          `${item.id.split("_")[2]}`
                      ).outerHTML;
                      const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
                      replaceStr = `<li contenteditable="false" id="${strId}">.*<span contenteditable="false" id="${item.id}">&nbsp;<\/span><\/div><\/div><\/li>`;
                      let str = value
                        .replace(/\n/g, "")
                        .replace(
                          new RegExp(replaceStr),
                          `<li>${strippedHtml}</li>`
                        );
                      handleEditorChange(str);
                    }
                  }}
                >
                  Replace?
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null}
      {modifyHtmlP() && Array.isArray(modifyHtmlP()) && modifyHtmlP().length ? (
        <div>
          {modifyHtmlP().map((item) => (
            <div
              style={{
                position: "absolute",
                top: `${item.doc.top + 50}px`,
                left: `${item.doc.left - 60}px`,
                zIndex: 2,
                // padding: "2px 5px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  cursor: "pointer",
                  border: "none",
                  fontWeight: 600,
                  color: "#261C4B",
                  padding: "2px 5px",
                  marginRight: "3px",
                }}
                onClick={() => {
                  let replaceStr = "";
                  if (item.id.split("_")[0] === "p" && !suggestionLoader) {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    replaceStr = `<div contenteditable="false" id="${
                      item.id.split("_")[0] +
                      "_" +
                      "div_" +
                      `${item.id.split("_")[2]}`
                    }".*<span contenteditable="false" id="${
                      item.id
                    }">&nbsp;<\/span><\/div><\/div>`;
                    let str = value.replace(new RegExp(replaceStr), ``);
                    handleEditorChange(
                      str
                        .replace(
                          `<p contenteditable="false" id="${strId}"`,
                          "<p"
                        )
                        .replace(/<p><br><\/p>/gm, "")
                    );
                  }
                }}
              >
                Ignore?
              </div>
              {checkForServerErrorForP(item) ? null : (
                <div
                  style={{
                    cursor: "pointer",
                    border: "none",
                    fontWeight: 600,
                    color: "#4389FA",
                    padding: "2px 5px",
                  }}
                  onClick={() => {
                    let strId =
                      `${item.id.split("_")[0]}` +
                      "_" +
                      `${item.id.split("_")[2]}`;
                    let replaceStr = "";
                    if (item.id.split("_")[0] === "p" && !suggestionLoader) {
                      const myHTML = document.getElementById(
                        item.id.split("_")[0] +
                          "_" +
                          "div_" +
                          `${item.id.split("_")[2]}`
                      ).outerHTML;
                      const strippedHtml = myHTML.replace(/<[^>]+>/g, "");
                      replaceStr = `<p contenteditable="false" id="${strId}">.*<span contenteditable="false" id="${item.id}">&nbsp;<\/span><\/div><\/div>`;
                      let str = value
                        .replace(/\n/g, "")
                        .replace(
                          new RegExp(replaceStr),
                          `<p>${strippedHtml}</p>`
                        );
                      handleEditorChange(str.replace(/<p><br><\/p>/gm, ""));
                    }
                  }}
                >
                  Replace?
                </div>
              )}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default NewEditor;
