/*eslint-disable*/
import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton } from "antd";

import Info from "../../assets/images/resumeOptimizer/Info.svg";
import User from "../../assets/images/resumeOptimizer/User.svg";
import Pen from "../../assets/images/resumeOptimizer/Pen.svg";
import Briefcase from "../../assets/images/resumeOptimizer/Briefcase.svg";
// import UserGear from "../../assets/images/resumeOptimizer/UserGear.svg";
import Student from "../../assets/images/resumeOptimizer/Student.svg";
// import CircleWavyCheck from "../../assets/images/resumeOptimizer/CircleWavyCheck.svg";
// import Plus from "../../assets/images/resumeOptimizer/Plus (gray).svg";
import FolderSimplePlus from "../../assets/images/resumeOptimizer/FolderSimplePlus.svg";

import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentResumePage } from "../../store/resumeOptimizer/resumeOptimizerActions";
import Certification from "./certification";
import EducationInfo from "./educationInfo";
import Skills from "./skills";
import ExperienceInfo from "./experienceInfo";
import Summary from "./summary";
import ContactInfo from "./contactInfo";
import Preview from "./preview";
import {
  ContactInfoSuggestion,
  DefaultSuggestion,
  EmployementSuggestion,
  SummarySuggestion,
  PreviewSuggestion,
  AssosiationSuggestion,
  EducationSuggestion
} from "./suggestions";
import AddOtheresModal from "./addOtheresModal";
import OtheresSections from "./otheresInfo";
import AchievmentsInfo from "./Achievements";
import AssociationsInfo from "./Associations";
import CertificationsInfo from "./certification";
import ReferencesInfo from "./References";
import MiliteryHistory from "./MiliteryHistory";
import Patent from "./PatentInfo";
import TrainigHistory from "./TrainginHistory";
import ConfirmationModal from "./confirmationModal";

const data = [
  { name: "Overview", imgSrc: Info, id: "all" },
  { name: "Personal Info", imgSrc: User, id: "personal_info" },
  { name: "Summary", imgSrc: Pen, id: "summary" },
  { name: "Experience", imgSrc: Briefcase, id: "experience" },
  // { name: "Skills", imgSrc: UserGear, id: "skills" },
  { name: "Education", imgSrc: Student, id: "education" },
  // { name: "Certfications", imgSrc: CircleWavyCheck, id: "certfications" },
];

const ResumeOptimizerBody = () => {
  const dispatch = useDispatch();
  const { currentResumePage, loader, unsavedExperience, resumeScreens } =
    useSelector((state) => state.resumeOptimizer);

  const [addOtheresModal, setaddOtheresModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const handleClick = (id) => {
    dispatch(setCurrentResumePage(id));
  };

  const getCurrentPage = () => {
    if (resumeScreens.map((item) => item.id).includes(currentResumePage))
      return <OtheresSections />;
    else {
      switch (currentResumePage) {
        case "personal_info":
          return <ContactInfo />;
        case "summary":
          return <Summary />;
        case "experience":
          return <ExperienceInfo />;
        case "education":
          return <EducationInfo />;
        case "skills":
          return <Skills />;
        case "certfications":
          return <Certification />;
        case "achievements":
          return <AchievmentsInfo />;
        case "associations":
          return <AssociationsInfo />;
        case "certifications":
          return <CertificationsInfo />;
        case "references":
          return <ReferencesInfo />;
        case "militaryhistory":
          return <MiliteryHistory />;
        case "patent":
          return <Patent />;
        case "trainingHistory":
          return <TrainigHistory />;
        case "otheresSections":
          return <OtheresSections />;
        default:
          return <Preview isEdit={true} />;
      }
    }
  };

  const getCurrentSuggestions = () => {
    console.log("OLSDLDL", currentResumePage);
    switch (currentResumePage) {

      case "personal_info":
        return <ContactInfoSuggestion />;
      case "summary":
        return <SummarySuggestion />;
      case "experience":
        return <EmployementSuggestion />;
      case "education":
        return <EducationSuggestion />;
      case "skills":
        return <DefaultSuggestion />;
      case "certfications":
        return <DefaultSuggestion />;
      case "all":
        return <PreviewSuggestion />;
      case "Associations":
        return <AssosiationSuggestion />;
      default:
        return <DefaultSuggestion />;
    }
  };

  useEffect(() => {
    if (unsavedExperience !== "") {
      // setConfirmationModal(!confirmationModal);
    }
  }, [unsavedExperience]);

  return (
    <>
      <Row className="resume_optimizer_body" justify="space-between">
        <Col xs={24} sm={24} md={17} lg={17}>
          <Row className="resume_optimizer_body_left">
            <Col xs={24} sm={24} md={3} lg={3}>
              <div className="RO_body_section">
                <div className="title">Sections:</div>
                {data.map((item, i) => (
                  <div
                    className={
                      item.id == currentResumePage ? "item_active" : "item"
                    }
                    onClick={() => {
                      if (item.id === "add") {
                        setaddOtheresModal(!addOtheresModal);
                      } else {
                        handleClick(item.id);
                      }
                    }}
                  >
                    <img src={item.imgSrc} alt={item.name} />
                    <div>{item.name}</div>
                  </div>
                ))}
                <div
                  className={
                    "otheresSections" == currentResumePage
                      ? "item_active"
                      : "item"
                  }
                  onClick={() => {
                    setaddOtheresModal(!addOtheresModal);
                  }}
                >
                  <img src={FolderSimplePlus} />
                  <div>Others</div>
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={21} lg={21} className="content">
              <Skeleton loading={loader} active>
                <div>{getCurrentPage()}</div>
              </Skeleton>
            </Col>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6}>
          <div className="resume_optimizer_body_right">
            {getCurrentSuggestions()}
          </div>
        </Col>
      </Row>
      <ConfirmationModal
        visible={confirmationModal}
        unsavedExperience={unsavedExperience}
      />
      <AddOtheresModal visible={addOtheresModal} />
    </>
  );
};

export default ResumeOptimizerBody;
