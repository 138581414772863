import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import UnnlockLayout from "../../containers/layout";
import JobCompanyCard from "./JobCompanyCard";
import Topbar from "./Topbar";
import FilterTab from "./FilterTab";
import "./JobCompanycard.scss";
import { Col, Row, Spin } from "antd";
// import { PreviewSuggestionWithoutInfo } from "../../containers/ResumeOpimizer/suggestions";
import history from "../../routes/History";
import * as JobMatchAction from "../../store/jobMatch/jobMatchActions";
import { acceptOverLayRequest } from "../../store/dashBoard/dashboardActions";
import { getUserDataSuccess } from "../../store/newLogin/newLoginAction";
import { setCurrentResumeId } from "../../store/resumeOptimizer/resumeOptimizerActions";
import { getOpenJobs, setOpenJobsResume } from "../../store/Tools/toolsActions";

const Companies = () => {
  const { openJobs: matchedjobsList, selectedResumeId } = useSelector(
    (state) => state.tools
  );
  const { jobTrackeres } = useSelector((state) => state.jobmatch);
  const [faveratesList, setFaveratesList] = useState([]);
  const [openJobListObject, setOpenJobListObject] = useState(undefined);
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.login.userData);
  const { resumeVaults } = useSelector((state) => state.resumeOptimizer);
  const selectedExploreSkills = JSON.parse(
    localStorage.getItem("openJobSkills")
  );
  // const { currentResume } = useSelector((state) => state.resumeOptimizer);
  const componiesLoader = useSelector((state) => state.tools.loading);

  const getFavClick = (
    operation,
    companyId,
    initialFormData,
    addJobMatchDirectly
  ) => {
    if (selectedResumeId) {
      dispatch(setCurrentResumeId(selectedResumeId));
      let postdata = {
        resumeId: selectedResumeId,
        fromOpenJobs: true,
        doNotSave: false,
        data: {
          company_name: initialFormData.COMPANY_NAME,
          company_url: Array.isArray(initialFormData.URL)
            ? initialFormData.URL[0]
            : "",
          job_title: initialFormData.TITLE_NAME,
          education: Array.isArray(initialFormData.EDUCATION_LEVELS_NAME)
            ? initialFormData.EDUCATION_LEVELS_NAME[0]
            : "",
          required_experience: initialFormData.MIN_YEARS_EXPERIENCE || "",
          text: initialFormData.BODY,
          open_job_id: initialFormData.ID,
          industry: initialFormData.TITLE_RAW,
          primary_resume: selectedResumeId,
        },
      };
      if (!addJobMatchDirectly) {
        let favArr = [];
        if (userData.favourite_jobs?.includes(`${companyId}`)) {
          favArr = userData.favourite_jobs.filter(
            (item) => item !== `${companyId}`
          );
        } else {
          favArr = [...userData.favourite_jobs, `${companyId}`];
        }
        dispatch(getUserDataSuccess({ ...userData, favourite_jobs: favArr }));
        dispatch(
          acceptOverLayRequest({
            key: "favourite_jobs",
            value: favArr,
          })
        );
        if (operation === "add") {
          // setclickCheckJob(true);
          dispatch(
            JobMatchAction.jobMatchResultsRequest({
              postdata,
              donotSave: false,
            })
          );
        }
      } else {
        if (
          userData?.oj_favorite &&
          userData?.oj_favorite.map((item) => item.ojId)?.includes(companyId)
        ) {
          let foundOpenJob = userData.oj_favorite.filter(
            (item) => item.ojId === companyId
          )[0];
          const resumeId = resumeVaults.filter(
            (item) => item.job_match_id === foundOpenJob.jMId
          )[0].resume_id;
          dispatch(setCurrentResumeId(resumeId));
          postdata = {
            ...postdata,
            doNotSave: true,
            resumeId: resumeId,
            data: {
              ...postdata.data,
              job_match_id: foundOpenJob.jMId,
              primary_resume: resumeId,
            },
          };
        }
        history.push({
          pathname: `/jobmatch`,
          state: {
            postdata,
            fromOpenJobs: true,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (userData.favourite_jobs && Array.isArray(userData.favourite_jobs)) {
      setFaveratesList(userData.favourite_jobs);
    }
    if (userData && userData.primary_resume && !jobTrackeres?.length) {
      dispatch(JobMatchAction.getJobTrackeresRequest({ limit: "all" }));
    }
  }, [userData]);

  useEffect(() => {
    if (!matchedjobsList?.jobs_list?.length) {
      dispatch(
        getOpenJobs({
          specialized_skills: selectedExploreSkills,
          state_list: [localStorage.getItem("openJobState")],
        })
      );
      dispatch(setOpenJobsResume(localStorage.getItem("openJobResume")));
    } else {
      setOpenJobListObject(matchedjobsList);
    }
  }, [matchedjobsList?.jobs_list]);

  return (
    <UnnlockLayout>
      <div className="OpenJobComapnies_container">
        <Spin size="large" spinning={componiesLoader}>
          {openJobListObject?.jobs_list?.length ? (
            <>
              <Topbar />
              <Row gutter={16} style={{ margin: "auto", width: "95%" }}>
                <Col span={6} style={{ paddingLeft: "0px" }}>
                  <FilterTab
                    companyFilterList={openJobListObject}
                    selectedExploreSkills={selectedExploreSkills}
                  />
                </Col>
                <Col span={18} style={{ paddingRight: "0px" }}>
                  {
                    // userData?.tier_id === 0
                    //   ? openJobListObject.jobs_list
                    //       .slice(0, 6)
                    //       .map((jobcompany, index) => {
                    //         // console.log(jobcompany)
                    //         if (index)
                    //           return (
                    //             <div key={jobcompany.ID}>
                    //               {/* {jobcompany.ID} */}
                    //               <JobCompanyCard
                    //                 item={jobcompany}
                    //                 isFaverate={
                    //                   faveratesList.includes(`${jobcompany.ID}`)
                    //                     ? true
                    //                     : false
                    //                 }
                    //                 getFavClick={getFavClick}
                    //               />
                    //             </div>
                    //           );
                    //       })
                    //   :
                    openJobListObject.jobs_list.map((jobcompany, index) => (
                      <div key={index}>
                        {/* {jobcompany.ID} */}
                        <JobCompanyCard
                          item={jobcompany}
                          isFaverate={
                            faveratesList.includes(`${jobcompany.ID}`)
                              ? true
                              : false
                          }
                          getFavClick={getFavClick}
                        />
                      </div>
                    ))
                  }
                </Col>
              </Row>
            </>
          ) : (
            <>
              {componiesLoader ? null : (
                <h3 style={{ textAlign: "center", padding: "20px" }}>
                  No Jobs Found With Selected Skills.
                </h3>
              )}
            </>
          )}
        </Spin>
      </div>
    </UnnlockLayout>
  );
};

export default Companies;

job_match_id: "4eaef2bb-409e-4841-adbb-97ff7ccb3b5b";

resume_id: "7ff4ed5d-3aae-4c92-9f37-c8781af0f6f4";
