/* eslint-disable */
import { put, takeLatest, call } from "redux-saga/effects";
import * as ACTIONS from "./registerActions";
import * as DASHBOARDACTIONS from "../dashBoard/dashboardActions";
import * as TYPES from "./registerTypes";
import Network from "../../network";
import unnlockNotification from "../../components/notification";
import {
  getUserDataRequest,
  getUserDataSuccess,
} from "../newLogin/newLoginAction";
import history from "../../routes/History";

const { API, Config } = Network;

function* handleRegistration(action) {
  try {
    const registrationData = action.payload;
    const registrationUrl = Config.registration.registerUser;
    const result = yield call(API.post, registrationUrl, registrationData);
    if (result.message) {
      unnlockNotification(result.message, "warning", 52);
      yield put(ACTIONS.registerSuccess({ data: "", userExistsAlready: true }));
    } else {
      yield put(
        ACTIONS.registerSuccess({ data: result, userExistsAlready: false })
      );
    }
  } catch (error) {
    yield put(ACTIONS.registerFailure(error));
  }
}

function* handleSaveUSerSubs(action) {
  try {
    const registrationData = action.payload;
    const subscriptionUpdationUrl = Config.registration.subscriptionUpdationUrl;
    const result = yield call(
      API.post,
      subscriptionUpdationUrl,
      registrationData,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yieldput(ACTIONS.saveUSerSubsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.saveUSerSubsFailure(error));
  }
}

function* handleResumeParse(action) {
  try {
    const postData = action.payload;
    const resumeParserUrl = Config.employmentDetail.newEmploymentDetailUrl(
      postData.userId
    );
    const raw = {
      file_name: postData.file_name,
      base64String: postData.resData.resumeBinary,
    };
    const result = yield call(API.post, resumeParserUrl, raw);
    yield put(ACTIONS.uploadToS3Request(postData));
    yield put(ACTIONS.uploadResumeSuccess(result));
    yield put(DASHBOARDACTIONS.resumeParserSuccess(result));
  } catch (error) {
    unnlockNotification("Error While Parsing The Resume", "error", 52);
    yield put(ACTIONS.uploadResumeFailure(error));
  }
}

function* handleUploadTOS3(action) {
  try {
    const postData = action.payload;
    var formdata = new FormData();
    formdata.append("file", postData.rawFile, postData.file_name);
    const resumeParserUrl = Config.employmentDetail.sendFileToS3(
      postData.userId
    );
    const result = yield call(API.post, resumeParserUrl, formdata);
    yield put(ACTIONS.uploadToS3Success(result));
  } catch (error) {
    unnlockNotification("Error While Parsing The Resume", "error", 52);
    yield put(ACTIONS.uploadToS3Failure(error));
  }
}

function* handleCheckEmail(action) {
  try {
    const postData = action.payload;

    const emailCheckURL = Config.employmentDetail.checkEmail(
      postData.userEmail
    );
    const result = yield call(API.get, emailCheckURL);
    yield put(ACTIONS.checkValidEmailSuccess(result));
  } catch (error) {
    yield put(ACTIONS.checkValidEmailFailure(error));
  }
}


function* handleGetAllSocs(action) {
  try {
    const result = yield call(API.get, Config.registration.getAllSocs, {
      Authorization: localStorage.getItem("unnlockUserToken"), // id_token
    });
    yield put(ACTIONS.getAllSocsSuccess(result));
  } catch (error) {
    yield put(ACTIONS.getAllSocsFailure(error));
  }
}

function* handleSaveUserSocs(action) {
  try {
    const params = action.payload;
    const result = yield call(
      API.put,
      Config.ProfileDetails.saveCompanyDatils,
      params,
      {
        Authorization: localStorage.getItem("unnlockUserToken"), // id_token
      }
    );
    yield put(ACTIONS.saveUserSocsSuccess(result));
    yield put(getUserDataSuccess(result));
    //history.push("/dashboard");
  } catch (error) {
    yield put(ACTIONS.saveUserSocsFailure(error));
  }
}

function* handleSocialLoginRegistration(action) {
  const params = action.payload;
  const { auth_type } = params;
  try {
    const { first_name, last_name, email, primary_resume, idToken } =
      action.payload;
    const socialRegistrationUrl = Config.registration.socialSignUp;
    const result = yield call(
      API.post,
      socialRegistrationUrl,
      {
        first_name,
        last_name,
        auth_type,
        email,
        primary_resume,
      },
      {
        Authorization: idToken,
      }
    );
    yield put(ACTIONS.socialRegisterSuccess(result));
  } catch (error) {
    yield put(
      ACTIONS.socialRegisterFailure({
        error,
      })
    );
  }
}

export function* requestSocialRegistration() {
  yield takeLatest(
    TYPES.SOCIAL_REGISTER_REQUEST,
    handleSocialLoginRegistration
  );
}
export function* requestRegistration() {
  yield takeLatest(TYPES.REGISTER_REQUEST, handleRegistration);
}
export function* requestResumeParse() {
  yield takeLatest(TYPES.UPLOAD_RESUME_REQUEST, handleResumeParse);
}

export function* requestUploadToS3() {
  yield takeLatest(TYPES.UPLOAD_TOS3_REQUEST, handleUploadTOS3);
}

export function* requestCheckEmail() {
  yield takeLatest(TYPES.CHECK_EMAIL_REQUEST, handleCheckEmail);
}

export function* requestAllSocs() {
  yield takeLatest(TYPES.GET_ALL_SOCS_REQUEST, handleGetAllSocs);
}

export function* requestSaveUserSocs() {
  yield takeLatest(TYPES.SAVE_USER_SOCS_REQUEST, handleSaveUserSocs);
}
export function* requestSaveUSerSubs() {
  yield takeLatest(TYPES.SAVE_USER_SUBS_REQUEST, handleSaveUSerSubs);
}
